import { useRef } from "react";
import { Link } from "react-router-dom";
import { CaseStudyInfo, Role, useAuthContext } from "@pull/pull-groupm-csp-api"
import { UserRoles } from "../../Constants";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { useCaseStudyEditContext } from "../../pages/case-studies/edit/_CaseStudyEditContainer";

function CaseStudyProgress(props: CaseStudyActionBarProps) {

    const { caseStudy, fetching } = useCaseStudyEditContext()
    const { currentSession } = useAuthContext();

    const GetElement = (stepText: String, stepSlug: String, targetStep: CaseStudyStep, check: (caseStudy?: CaseStudyInfo) => Boolean): JSX.Element => {

        var isComplete = check(caseStudy)
        var isActive = props.step == targetStep


        if (isComplete || isActive || (currentSession?.role == Role.Administrator && caseStudy != null)) {

            var classes = "";
            if (isActive)
                classes += " active"
            if (isComplete)
                classes += " ok"

            return (
                <li className={classes}>
                    <Link to={`/casestudies/${caseStudy?.id}/${stepSlug}`}>{stepText}</Link>
                </li>
            )
        }
        else {
            return (
                <li className="disabled">
                    <a href="#">{stepText}</a>
                </li>
            )
        }

    }

    return (
        <section className="subnav colour-lightgrey">
            <div className="container clearfix">
                <ul className="subnav numbered left">
                    {GetElement("Case Study Text", "edit", CaseStudyStep.Text, () => CaseStudyHelpers.isTextComplete(caseStudy))}
                    {GetElement("Tags", "edittags", CaseStudyStep.Tags, () => CaseStudyHelpers.isTagsComplete(caseStudy))}
                    {GetElement("Images", "editimages", CaseStudyStep.Images, () => CaseStudyHelpers.isImagesComplete(caseStudy))}
                    {GetElement("Video", "editvideo", CaseStudyStep.Video, () => CaseStudyHelpers.isVideosComplete(caseStudy))}
                    {GetElement("Awards", "editawards", CaseStudyStep.Awards, () => CaseStudyHelpers.isAwardsComplete(caseStudy))}
                    {GetElement("Publish", "publish", CaseStudyStep.Publish, () => CaseStudyHelpers.isPublished(caseStudy))} 
                </ul >
                {
                    caseStudy?.id ?
                        <ul className="subnav right">
                            <li className="preview">
                                <Link to={`/casestudies/${caseStudy.published ? caseStudy.id : caseStudy.hash}`}>Preview</Link></li>
                        </ul> : ""
                }
            </div >
        </section >
    )

}

export enum CaseStudyStep {
    Text,
    Tags,
    Images,
    Video,
    Awards,
    Publish
}

interface CaseStudyActionBarProps {
    step: CaseStudyStep
}


export { CaseStudyProgress }