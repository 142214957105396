import { useEffect, useState } from "react"
import { CaseStudyInfo } from "@pull/pull-groupm-csp-api"
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard"
import { useUserContext } from "../../modules/user/UserContext"
import { Helmet } from "react-helmet"

export const DraftCaseStudies = () => {

    const { userCaseStudies } = useUserContext()
    var [draftCaseStudies, setDraftCaseStudies] = useState<CaseStudyInfo[]>()

    useEffect(() => {
        setDraftCaseStudies(userCaseStudies?.filter(cs => !cs.published))
    }, [userCaseStudies])

    return (
        <div>
            <Helmet>
                <title>My Draft Case Studies</title>
            </Helmet>
            <section className="pageheading">
                <div className="colour-tertiary clearfix">
                    <div className="container">
                        <h1 className="left">My Draft Case Studies</h1>
                    </div>
                </div>
            </section>
            <div className="container bodycopy">
                <p>
                    This list shows all your current drafts.  You can continue editing and publishing these at any time.
                </p>
                <div className="searchresults clearfix list">
                    {draftCaseStudies?.map(cs => {
                        return <CaseStudyCard key={cs.id} caseStudy={cs} />
                    })}
                </div>
            </div>
        </div>
    )
}