import { TagValueInfo } from "@pull/pull-groupm-csp-api";
import { useState } from "react";

export function CaseStudyTagAutoComplete(props: ICaseStudyTagAutoCompleteProps) {

    var [tagName, setTagName] = useState("")
    var [tagSuggestions, setTagSuggestions] = useState(new Array<TagValueInfo>)
    var [showError, setShowError] = useState(false)

    async function tagInput(e: React.FormEvent<HTMLInputElement>) {

        setTagName(e.currentTarget.value)

        if (e.currentTarget.value.length >= 3) {
            var options = props.tags.filter(t => t.value?.toLowerCase().startsWith(e.currentTarget.value.toLowerCase())).slice(0, 10)
            setTagSuggestions(options)
        }
        else {
            setTagSuggestions([])
        }
    }

    async function tagSelected(tagValueID: number) {
        props.tagSelected(tagValueID)
        setTagSuggestions([])
        setTagName("")
    }

    async function tagAdded(tagValue: string) {

        if (tagValue.length < 3) {
            setShowError(true)
            return
        }

        //Try and find a match before adding a new one
        var options = props.tags.filter(t => t.value?.toLowerCase().startsWith(tagValue.toLowerCase())).slice(0, 10)
        if (options.length > 0) {
            tagSelected(options[0].id ?? 0)
            return;
        }

        props.tagAdded(tagValue)
        setTagSuggestions([])
        setTagName("")
        setShowError(false)
    }

    return (
        <div className="absolute addfield active">
            <input type="text" value={tagName} onChange={tagInput} />
            <a href="#" className="button no3d blue" onClick={() => tagAdded(tagName)}>Add +</a>
            {showError ?
                <div className="error">Must be at least 3 characters</div> : ""}


            {tagSuggestions.length > 0 ?
                <div>
                    <div className="alert error">
                        <p>
                            A similar tag(s) already exists. To avoid duplication please choose from the list below, or you can continue to create a new tag if you wish.
                        </p>
                    </div>
                    <div>
                        Did you mean
                        <ul className="tag-suggestions" role="list">
                            {tagSuggestions.map(ts => {
                                return <li>
                                    <a href="#" className="button tag-button" onClick={() => tagSelected(ts.id ?? 0)}>{ts.value}</a>
                                </li>
                            })}
                        </ul>
                    </div>
                </div> : ""}
        </div>
    )
}

export interface ICaseStudyTagAutoCompleteProps {
    tags: Array<TagValueInfo>
    tagAdded: (tagValue: string) => void
    tagSelected: (tagValueID: number) => void
}