import { useEffect, useState } from "react"
import { CaseStudyInfo } from "@pull/pull-groupm-csp-api"
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard"
import { useUserContext } from "../../modules/user/UserContext"
import { Helmet } from "react-helmet"

export const PublishedCaseStudies = () => {

    const { userCaseStudies } = useUserContext()
    var [publishedCaseStudies, setPublishedCaseStudies] = useState<CaseStudyInfo[]>()

    useEffect(() => {
        setPublishedCaseStudies(userCaseStudies?.filter(cs => cs.published))
    }, [userCaseStudies])

    return (
        <div>
            <Helmet>
                <title>My Case Studies</title>
            </Helmet>
            <section className="pageheading">
                <div className="colour-tertiary clearfix">
                    <div className="container">
                        <h1 className="left">My Case Studies</h1>
                    </div>
                </div>
            </section>
            <div className="container bodycopy">
                <p>
                This list shows all your published case studies.
                </p>
                <div className="searchresults clearfix list">
                    { publishedCaseStudies?.map(cs => {
                        return <CaseStudyCard key={cs.id} caseStudy={cs} />
                    })}
                </div>
            </div>
        </div>
    )
}