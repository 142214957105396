import { AwardsApi, NewsApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { useQuery, useQueryClient } from "react-query"
import { Queries } from "../../Constants";
import { AwardJudgingPanel } from "../../modules/awards/AwardJudgingPanel";
import { AwardSubmissionCard } from "../../modules/awards/AwardSubmissionCard";
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard";
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { ErrorComponent } from "../../modules/core/ErrorComponent";
import { useState } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { DataHelpers } from "../../helpers/dataHelpers";
import { LatestNewsPanel } from "../../modules/news/LatestNewsPanel";
import { Helmet } from "react-helmet";

function NewsArticle() {

    var params = useParams();
    var newsID = params.id ? parseInt(params.id) : 0;
    const { getApiConfiguration } = useAuthContext()
    const newsApi = new NewsApi(getApiConfiguration())


    const fetchNewsArticle = async () => {
        return await newsApi.apiNewsIdIdGet(newsID);
    }

    var { data: news, status: newsStatus } = useQuery(Queries.News + newsID, fetchNewsArticle)

    return <>
        <Helmet>
            <title>{news?.title}</title>
        </Helmet>
        <section className="pageheading">
            <div className="colour-tertiary clearfix">
                <div className="container">
                    <h1 className="left">Latest News</h1>
                </div>
            </div>
        </section>
        <div className="container bodycopy">
            {newsStatus === "error" && <ErrorComponent></ErrorComponent>}
            {newsStatus === "loading" && <LoadingComponent height='calc(90vh - 119px)'></LoadingComponent>}
            {newsStatus === "success" && (
                <>
                    <article className="newsarticle">
                        <h1>{news?.title}
                            <span className="datetext"> {moment(news?.metadata?.created).format("D MMMM YYYY")}</span></h1>
                        <p dangerouslySetInnerHTML={{ __html: DataHelpers.convertToHtml(news?.content) }}></p>
                        <p>
                            <Link to="/announcements" className="button">&lt; &nbsp;Back to all news</Link>
                        </p>
                    </article>

                </>
            )}
        </div>
        <LatestNewsPanel></LatestNewsPanel>
    </>
}

export { NewsArticle }

