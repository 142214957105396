import { BulkDownloadsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { Link } from "react-router-dom"
import { LoadingComponent } from "../../modules/core/LoadingComponent"
import { BulkDownloadCollectionCard } from "../../modules/user/BulkDownloadCollectionCard"
import { useQuery } from "react-query"
import { Queries, Urls } from "../../Constants"
import { ErrorComponent } from "../../modules/core/ErrorComponent"

export const BulkDownloadsHistory = () => {

    const getUserBulkDownloads = async () => {
        var bulkDownloadsApi = new BulkDownloadsApi(getApiConfiguration());
        var collections = await bulkDownloadsApi.apiBulkDownloadsMeGet();
        return collections.filter(collection => collection.downloaded)
    }

    const { getApiConfiguration } = useAuthContext()
    const { data, status } = useQuery(Queries.UserCollections, getUserBulkDownloads);

    return (
        <div>
            <section className="pageheading">
                <div className="colour-tertiary clearfix">
                    <div className="container">
                        <h1 className="left">Bulk Downloads</h1>
                    </div>
                </div>
            </section>
            <section className="subnav colour-lightgrey">
                <div className="container clearfix">
                    <ul className="subnav relative left">
                        <div className="subnav-toggle">
                            <strong>Menu</strong>
                        </div>
                        <li className="">
                            <Link to={Urls.UserBulkDownloads}>Downloads</Link>
                        </li>
                        <li className="active">
                            <Link to={Urls.UserBulkDownloadsHistory}>Download History</Link>
                        </li>
                    </ul>
                </div>
            </section>
            <div className="container bodycopy material">
                <p>
                    You can find all your previously downloaded collections here.
                </p>
                <div className="row clearfix bulkdownloads">
                    <div className="col s12">
                        {status === "error" && <ErrorComponent></ErrorComponent>}
                        {status === "loading" && <LoadingComponent></LoadingComponent>}
                        {status === "success" && (
                            (data?.map(collection => {
                                return <BulkDownloadCollectionCard key={collection.id} collection={collection}></BulkDownloadCollectionCard>
                            }))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}