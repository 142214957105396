import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CaseStudiesApi, CaseStudyInfo, Role, useAuthContext } from "@pull/pull-groupm-csp-api"
import { UserRoles } from "../../Constants";
import { useState } from "react";
import { BulkDownloadsApi } from "@pull/pull-groupm-csp-api";
import { useUserContext } from "../user/UserContext";
import { hostname } from "os";

function CaseStudyActionBar(props: CaseStudyActionBarProps) {

    const DEFAULT_COPY_TEXT = "Copy link to clipboard"
    const { getApiConfiguration } = useAuthContext();
    const { currentSession } = useAuthContext();
    const { userCurrentBulkDownloads, addCaseStudyToBulkDownloads, removeCaseStudyFromBulkDownloads, updateUserCaseStudies } = useUserContext();
    const downloadDropdown = useRef<HTMLDivElement>(null);
    const shareDropdown = useRef<HTMLDivElement>(null);
    const [copyText, setCopyText] = useState(DEFAULT_COPY_TEXT);
    const caseStudyApi = new CaseStudiesApi(getApiConfiguration());

    function toggleDownloadDropdown(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        downloadDropdown.current?.classList.toggle("open")
    }

    function toggleShareDropdown(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        shareDropdown.current?.classList.toggle("open")
    }

    function copyToClipboard(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        navigator.clipboard.writeText(`${document.location.protocol}//${document.location.host}/casestudies/${props.caseStudy.id}`)
        setCopyText("Copied!");
        setTimeout(() => {
            setCopyText(DEFAULT_COPY_TEXT)
        }, 1000);
    }

    async function deleteCaseStudy(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        var result = await caseStudyApi.apiCaseStudiesIdDelete(props.caseStudy.id ?? 0)

        if(result)
            updateUserCaseStudies();
    }

    async function addToBulkDownloads(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        await addCaseStudyToBulkDownloads([props.caseStudy.id ?? 0])
        downloadDropdown.current?.classList.remove("open")
    }

    async function removeFromBulkDownloads(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        await removeCaseStudyFromBulkDownloads(props.caseStudy.id ?? 0)
        downloadDropdown.current?.classList.remove("open")
    }

    async function registerDownload() {
        await caseStudyApi.apiCaseStudiesIdDownloadPost(props.caseStudy.id ?? 0)
    }

    return (
        <div className="icons">
            {props.caseStudy.published &&
                <>
                    <div ref={downloadDropdown} className="download dropdown">
                        <a href="#" onClick={toggleDownloadDropdown}>
                            <svg className="icon icon-icon-57-document-download2"><use xlinkHref="#icon-icon-57-document-download2"></use></svg>
                            <svg className="icon icon-icon-57-document-download"><use xlinkHref="#icon-icon-57-document-download"></use></svg>
                            <strong>Download</strong>
                        </a>
                        <div className="dropdown-item from-left">
                            <ul>
                                <li>
                                    {props.caseStudy.downloads?.pdfDownload ?
                                        <a onClick={registerDownload} href={props.caseStudy.downloads?.pdfDownload} className="powerpoint download" target="_blank"> PDF (.pdf)</a> :
                                        <a className="powerpoint disabled"> Generating PDF...</a>
                                    }
                                </li>
                                <li>
                                    {props.caseStudy.downloads?.pptxDownload ?
                                        <a onClick={registerDownload} href={props.caseStudy.downloads?.pptxDownload} className="powerpoint download" target="_blank"> PowerPoint (.pptx)</a> :
                                        <a className="powerpoint disabled"> Generating PowerPoint...</a>
                                    }
                                </li>
                                {userCurrentBulkDownloads.indexOf(props.caseStudy.id ?? 0) == -1 && (props.caseStudy.downloads?.pptxDownload || props.caseStudy.downloads?.pdfDownload) ?
                                    <li><a href="#" onClick={addToBulkDownloads} className="bulk" data-casestudyid="108573">Add to Bulk<br /> Downloads (.ZIP)</a></li> : ""
                                }
                                {userCurrentBulkDownloads.indexOf(props.caseStudy.id ?? 0) > -1 ?
                                    <li><a href="#" onClick={removeFromBulkDownloads} className="bulk" data-casestudyid="108573">Remove from Bulk<br /> Downloads</a></li> : ""
                                }
                            </ul>
                        </div>
                    </div>
                    <div ref={shareDropdown} className="share dropdown">
                        <a href="#" onClick={toggleShareDropdown}>
                            <svg className="icon icon-icon-5-mail-envelope-open2"><use xlinkHref="#icon-icon-5-mail-envelope-open2"></use></svg>
                            <svg className="icon icon-icon-5-mail-envelope-open"><use xlinkHref="#icon-icon-5-mail-envelope-open"></use></svg>
                            <strong>Share</strong>
                        </a>
                        <div className="dropdown-item from-left">
                            <ul>
                                <li>
                                    <a href={`mailto:?subject=Mindshare Case Study - ${props.caseStudy.title}&body=Mindshare Case Study: ${props.caseStudy.title} - ${window.location.origin}/casestudies/${props.caseStudy.id}`}>
                                        Email
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick={copyToClipboard}>
                                        {copyText}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            }
            {props.caseStudy.metadata?.createdByID == currentSession?.userID || currentSession?.role == Role.Administrator ?
                <div className="edit">
                    <Link to={`/casestudies/${props.caseStudy.id}/edit`}>
                        <svg className="icon icon-icon-136-document-edit2"><use xlinkHref="#icon-icon-136-document-edit2"></use></svg>
                        <svg className="icon icon-icon-136-document-edit"><use xlinkHref="#icon-icon-136-document-edit"></use></svg>
                        <strong>Edit</strong>
                    </Link>
                </div> : ""}
            {props.caseStudy.metadata?.createdByID == currentSession?.userID && !props.caseStudy.published ?
                <div className="delete">
                    <a href="#" onClick={deleteCaseStudy}>
                        <svg className="icon icon-icon-26-trash-can"><use xlinkHref="#icon-icon-26-trash-can"></use></svg>
                        <svg className="icon icon-icon-26-trash-can"><use xlinkHref="#icon-icon-26-trash-can"></use></svg>
                        <strong>Delete</strong>
                    </a>
                </div> : ""}
        </div>
    )
}

interface CaseStudyActionBarProps {
    caseStudy: CaseStudyInfo,
}


export { CaseStudyActionBar }