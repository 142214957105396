import { useParams, useNavigate } from 'react-router-dom';
import { Async, IfFulfilled, useAsync } from "react-async";
import { CaseStudyProgress, CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress";
import { Field, Form, Formik, FormikErrors, useFormik, useFormikContext } from 'formik';
import { Suspense, useEffect, useState } from "react";
import 'materialize-css';
import { Textarea } from 'react-materialize'
import $ from 'jquery'
import * as Yup from 'yup'
import { CaseStudyHelpers } from "../../../helpers/caseStudyHelpers";
import { IEditCaseStudyStepProps, useCaseStudyEditContext } from "./_CaseStudyEditContainer";
import { CaseStudyWorkflowManager } from "../../../managers/CaseStudyWorkflowManager";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import { useUserContext } from "../../../modules/user/UserContext";
import { CaseStudiesApi, CaseStudyInfo, Configuration, useAuthContext } from '@pull/pull-groupm-csp-api'
import { useGlobalContext } from '../../../modules/core/GlobalContext';
import { Tooltip } from '../../../modules/general/ToolTip';
import { Settings, Urls } from '../../../Constants';

export function CaseStudyEditText(props: IEditCaseStudyStepProps) {

    const navigate = useNavigate();
    const { getSetting } = useGlobalContext()
    const { getApiConfiguration } = useAuthContext();
    const { caseStudy, fetching } = useCaseStudyEditContext()

    
    useEffect(() => {
        redrawMaterialize()
    }, [fetching])
    
    async function redrawMaterialize() {
        setTimeout(() => {
            $('.materialize-textarea').each((index, item) => {
                M.textareaAutoResize(item)
                M.CharacterCounter.init(item)
            })
        }, 100)
    }

    return (
        (!fetching ?
            <div>
                <div className="container bodycopy material">
                    <Formik
                        initialValues={{
                            id: 0,
                            title: caseStudy?.title,
                            challenge: caseStudy?.fields?.challenge,
                            idea: caseStudy?.fields?.idea,
                            insight: caseStudy?.fields?.insight,
                            delivery: caseStudy?.fields?.delivery,
                            results: caseStudy?.fields?.results,
                            summary: caseStudy?.fields?.summary,
                            action: ""
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string()
                                .required("Title can't be blank"),
                            challenge: Yup.string()
                                .required("Challenge can't be blank"),
                            idea: Yup.string()
                                .required("Idea can't be blank"),
                            insight: caseStudy?.isLegacy ?
                                Yup.string().nullable() :
                                Yup.string().nullable().required("Insight can't be blank"),
                            delivery: Yup.string()
                                .required("Delivery can't be blank"),
                            results: Yup.string()
                                .required("Results can't be blank"),
                            summary: caseStudy?.isLegacy ?
                                Yup.string().nullable() :
                                Yup.string().nullable().required("Summary can't be blank"),
                        })}
                        onSubmit={async (values) => {
                            values.id = caseStudy?.id ?? 0;

                            var workflowManager = new CaseStudyWorkflowManager(new CaseStudiesApi(getApiConfiguration()))
                            var result = await workflowManager.saveCaseStudyText(values)

                            if (result?.id) {
                                props.onComplete(CaseStudyStep.Text, values.action == "drafts", result.id)
                            }
                            else
                                navigate("/")
                        }}
                        validate={async () => {
                            setTimeout(redrawMaterialize, 1)
                            window.scrollTo(0, 0);
                            return true
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ isSubmitting, handleSubmit, isValid, getFieldProps, errors, setFieldValue }) => (

                            <div>
                                {isSubmitting ? <LoadingComponent></LoadingComponent> :

                                    <form onSubmit={handleSubmit}>

                                        {CaseStudyHelpers.isTextComplete(caseStudy) ?
                                            <div className="alert ok">
                                                <p>This section is complete</p>
                                            </div> :
                                            <div className="alert error">
                                                <p>This section is incomplete</p>
                                            </div>}

                                        {!isValid && (
                                            <div className="alert error">
                                                <p>
                                                    {errors.title && <p>{errors.title}</p>}
                                                    {errors.challenge && <p>{errors.challenge}</p>}
                                                    {errors.idea && <p>{errors.idea}</p>}
                                                    {errors.insight && <p>{errors.insight}</p>}
                                                    {errors.delivery && <p>{errors.delivery}</p>}
                                                    {errors.results && <p>{errors.results}</p>}
                                                    {errors.summary && <p>{errors.summary}</p>}
                                                </p>
                                            </div>
                                        )}

                                        <div className="row clearfix">
                                            <div className="col s12">
                                                 <div className="row clearfix">
                                                    <div className="input-field col s12 withinfo">
                                                        <Tooltip tooltip={getSetting(Settings.CaseStudyTitleTooltip)}></Tooltip>
                                                        <input {...getFieldProps('title')} type="text" className={"validate " + (errors.title && "invalid")} placeholder={getSetting(Settings.CaseStudyTitleTooltip)} maxLength={30} />
                                                        <label className="active">Campaign Title</label>
                                                    </div>
                                                </div>
                                                {!caseStudy?.isLegacy && (
                                                    <div className="row clearfix">
                                                        <div className="input-field col s12 withinfo">
                                                            <Tooltip tooltip={getSetting(Settings.CaseStudySummaryTooltip)}></Tooltip>
                                                            <textarea {...getFieldProps('summary')} className={"materialize-textarea validate " + (errors.summary && "invalid")} rows={5} placeholder={getSetting(Settings.CaseStudySummaryTooltip)} data-length="350" maxLength={350} />
                                                            <label className="active">Summary</label>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row clearfix">
                                                    <div className="input-field col s12 withinfo">
                                                        <Tooltip tooltip={getSetting(Settings.CaseStudyChallengeTooltip)}></Tooltip>
                                                        <textarea {...getFieldProps('challenge')} className={"materialize-textarea validate " + (errors.challenge && "invalid")} placeholder={getSetting(Settings.CaseStudyChallengeTooltip)} data-length="500" maxLength={500} />
                                                        <label className="active">Challenge</label>
                                                    </div>
                                                </div>
                                                {!caseStudy?.isLegacy && (
                                                    <div className="row clearfix">
                                                        <div className="input-field col s12 withinfo">
                                                            <Tooltip tooltip={getSetting(Settings.CaseStudyInsightTooltip)}></Tooltip>
                                                            <textarea {...getFieldProps('insight')} className={"materialize-textarea validate " + (errors.insight && "invalid")} rows={5} placeholder={getSetting(Settings.CaseStudyInsightTooltip)} data-length="700" maxLength={700} />
                                                            <label className="active">Insight</label>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row clearfix">
                                                    <div className="input-field col s12 withinfo">
                                                        <Tooltip tooltip={getSetting(Settings.CaseStudyIdeaTooltip)}></Tooltip>
                                                        <textarea {...getFieldProps('idea')} className={"materialize-textarea validate " + (errors.idea && "invalid")} rows={5} placeholder={getSetting(Settings.CaseStudyIdeaTooltip)} data-length="700" maxLength={700} />
                                                        <label className="active">Idea</label>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="input-field col s12 withinfo">
                                                        <Tooltip tooltip={getSetting(Settings.CaseStudyDeliveryTooltip)}></Tooltip>
                                                        <textarea {...getFieldProps('delivery')} className={"materialize-textarea validate " + (errors.delivery && "invalid")} rows={5} placeholder={getSetting(Settings.CaseStudyDeliveryTooltip)} data-length="700" maxLength={700} />
                                                        <label className="active">Delivery</label>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="input-field col s12 withinfo">
                                                        <Tooltip tooltip={getSetting(Settings.CaseStudyResultsTooltip)}></Tooltip>
                                                        <textarea  {...getFieldProps('results')} className={"materialize-textarea validate " + (errors.results && "invalid")} rows={5} placeholder={getSetting(Settings.CaseStudyResultsTooltip)} data-length="500" maxLength={500} />
                                                        <label className="active">Results</label>
                                                    </div>
                                                </div>
                                                <div className="clearfix button-block">
                                                    <button disabled={isSubmitting} type="submit" id="proceed" className="button right no3d">
                                                        Save and Continue &nbsp; &gt;
                                                    </button>
                                                    {!caseStudy?.published &&
                                                        <button type="submit" id="drafts" onClick={() => setFieldValue("action", "drafts")} className="back-button" name="drafts" formNoValidate>Save to drafts</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>}
                            </div>
                        )}
                    </Formik>
                </div>
            </div >

            : <LoadingComponent loadingAnimationDelay={0}></LoadingComponent>)
    )
}
