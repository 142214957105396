import { useEffect, useState } from "react"
import { CaseStudiesApi, CaseStudyInfo, CountryInfo, RegionInfo, SavedSearchInfo, TagValueInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard"
import { useUserContext } from "../../modules/user/UserContext"
import { useQuery, useQueryClient } from "react-query"
import { LoadingComponent } from "../../modules/core/LoadingComponent"
import moment from "moment"
import { WatchlistCard } from "../../modules/user/WatchlistCard"
import { Queries } from "../../Constants"
import { ErrorComponent } from "../../modules/core/ErrorComponent"
import { Helmet } from "react-helmet"

export const Watchlist = () => {

    const { getApiConfiguration } = useAuthContext()

    const getUserWatchlists = async () => {
        var caseStudiesApi = new CaseStudiesApi(getApiConfiguration());
        return await caseStudiesApi.apiCaseStudiesSavedSearchGet();
    }

    const { data, status } = useQuery(Queries.UserWatchlists, getUserWatchlists, {refetchOnWindowFocus: false});

    return (
        <div>
            <Helmet>
                <title>Search Watchlist</title>
            </Helmet>
            <section className="pageheading">
                <div className="colour-tertiary clearfix">
                    <div className="container">
                        <h1 className="left">Search Watchlist</h1>
                    </div>
                </div>
            </section>
            <div className="container bodycopy">
                <div className="alert normal">
                    <p>This page lists all your saved searches.  If any case studies are added that meet your saved criteria you will be alerted and can quickly re-run your search.</p>
                </div>
                <div className="watchlist-results">
                    {status === "error" && <ErrorComponent></ErrorComponent>}
                    {status === "loading" && <LoadingComponent></LoadingComponent>}
                    {status === "success" && (
                        <>
                            {data.length > 0 && data?.map(watchlist => {
                                return (
                                    <WatchlistCard key={watchlist.id} watchlist={watchlist}></WatchlistCard>
                                )
                            })}
                            {data?.length == 0 ?
                                <p>You don't have any saved searches yet.</p> : ""
                            }
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}