
export class DataHelpers {

    static b64toBlob(dataURI: string) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: 'image/jpeg' });
        return new File([blob], "updated-image.jpg")
    }

    static convertToHtml(data:string|undefined){
        return data?.replaceAll("\r\n", "<br />") ?? "";
    }
}