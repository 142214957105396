import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AwardCategoryInfo, CaseStudiesApi, CaseStudyInfo, CountryInfo, RegionInfo, Role, SavedSearchInfo, TagValueInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { Queries, UserRoles } from "../../Constants";
import moment from 'moment'
import { BulkDownloadCollectionInfo } from "@pull/pull-groupm-csp-api";
import { useState } from "react";
import { CaseStudyCard } from "../case-studies/CaseStudyCard";
import { useQueryClient } from "react-query";
import { ISearchFormProps } from "../../pages/case-studies/CaseStudySearch";
import { useUserContext } from "./UserContext";

export function WatchlistCard(props: WatchlistCardProps) {

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { getApiConfiguration } = useAuthContext()
    const { recordUserLastSavedSearchRun } = useUserContext()

    const getTagValues = (values:TagValueInfo[]|undefined) => {
        if(!values || values.length == 0)
            return "All";

        return values.map(tv => tv.value).join(", ");
    }

    const getName = (values:CountryInfo[]|RegionInfo[]|AwardCategoryInfo[]|undefined) => {
        if(!values || values.length == 0)
            return "All";

        return values.map(tv => tv.name).join(", ");
    }
    
    const deleteWatchlist = async (e:React.MouseEvent) => {
        e.preventDefault()
        var caseStudiesApi = new CaseStudiesApi(getApiConfiguration());
        await caseStudiesApi.apiCaseStudiesSavedSearchIdDelete(props.watchlist.id ?? 0);
        queryClient.invalidateQueries(Queries.UserWatchlists)
    }

    const searchWatchlist = async () => {
        var searchParams = {
            searchText: props.watchlist.savedSearchCaseStudyInfo?.keywords,
            advertisers: props.watchlist.savedSearchCaseStudyInfo?.selectedAdvertisers?.map(c => c.id),
            countries: props.watchlist.savedSearchCaseStudyInfo?.selectedCountries?.map(c => c.id),
            regions: props.watchlist.savedSearchCaseStudyInfo?.selectedRegions?.map(c => c.id),
            channels: props.watchlist.savedSearchCaseStudyInfo?.selectedChannels?.map(c => c.id),
            sectors: props.watchlist.savedSearchCaseStudyInfo?.selectedSectors?.map(c => c.id),
            objectives: props.watchlist.savedSearchCaseStudyInfo?.selectedObjectives?.map(c => c.id),
            audiences: props.watchlist.savedSearchCaseStudyInfo?.selectedAudiences?.map(c => c.id),
            awardsCategories: props.watchlist.savedSearchCaseStudyInfo?.selectedAwardCategories?.map(c => c.id),
            awardWinning: props.watchlist.savedSearchCaseStudyInfo?.awardWinningOnly,
            goodGrowth: props.watchlist.savedSearchCaseStudyInfo?.goodGrowthOnly
         } as ISearchFormProps
         
        var caseStudiesApi = new CaseStudiesApi(getApiConfiguration());
        await caseStudiesApi.apiCaseStudiesSavedSearchIdSearchGet(props.watchlist.id ?? 0)
        queryClient.invalidateQueries(Queries.UserWatchlists)
        recordUserLastSavedSearchRun()
        navigate("/search", { state: searchParams})
    }

    return (
        <div className="shadowbox watchlist-block">
            <div className="watchlist-term">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="butt" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                <h2 className="watchlist-heading">{props.watchlist.savedSearchCaseStudyInfo?.keywords != "" ? props.watchlist.savedSearchCaseStudyInfo?.keywords : moment(props.watchlist.created).format("DD MMM YY HH:mm")}</h2>
                {props.watchlist.newCaseStudies && props.watchlist.newCaseStudies > 0 ?
                    <div className="pill pill-secondary">New Activity ({props.watchlist.newCaseStudies})</div> : ""}
            </div>
            <ul>
                <li><strong>Advertisers: </strong><>{getTagValues(props.watchlist?.savedSearchCaseStudyInfo?.selectedAdvertisers)}</></li>
                <li><strong>Awards: </strong><>{getName(props.watchlist?.savedSearchCaseStudyInfo?.selectedAwardCategories)}</></li>
                <li><strong>Sectors: </strong><>{getTagValues(props.watchlist?.savedSearchCaseStudyInfo?.selectedSectors)}</></li>
                <li><strong>Regions: </strong>{getName(props.watchlist?.savedSearchCaseStudyInfo?.selectedRegions)}</li>
                <li><strong>Channels: </strong>{getTagValues(props.watchlist?.savedSearchCaseStudyInfo?.selectedChannels)}</li>
                <li><strong>Countries: </strong>{getName(props.watchlist?.savedSearchCaseStudyInfo?.selectedCountries)}</li>
                <li><strong>Marketing Objectives: </strong>{getTagValues(props.watchlist?.savedSearchCaseStudyInfo?.selectedObjectives)}</li>
                <li><strong>Award Winning Only: </strong>{props.watchlist?.savedSearchCaseStudyInfo?.awardWinningOnly ? "Yes" : "No"}</li>
                {/* <li><strong>Good Growth Only: </strong>{props.watchlist?.savedSearchCaseStudyInfo?.goodGrowthOnly ? "Yes" : "No"}</li> */}
            </ul>
            <div className="actions colour-lightgrey bodycopy">
                <a href="#" onClick={(e) => deleteWatchlist(e)}>Delete Search</a>
                <button onClick={() => searchWatchlist()} className="button">Search Again</button>
            </div>
        </div>
    )
}

interface WatchlistCardProps {
    watchlist: SavedSearchInfo
}