import { Route, Routes, Outlet } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserInfo, UsersApi, useAuthContext } from '@pull/pull-groupm-csp-api';
import Async from 'react-async';
import { useLoading } from '../modules/core/LoadingProvider';

const NotFound = () => {

  const { getApiConfiguration, currentSession } = useAuthContext();

  const { setLoading, loading } = useLoading();
  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  return (
    <div className="container">
      <div id="wrap">
        <div className="status-page">
          <h1>Sorry!</h1>
          <h1>The page you tried to access doesn't exist..</h1>
          <h1>Apologies for any inconvenience caused.</h1>
        </div>
      </div>
    </div>
  )
}

export { NotFound }
