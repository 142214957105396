import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'
import { CaseStudyInfo, CaseStudiesApi, useAuthContext } from '@pull/pull-groupm-csp-api';
import { WithChildren } from '../../helpers/react18MigrationHelpers';
import { BulkDownloadsApi } from '@pull/pull-groupm-csp-api';
import { Queries } from '../../Constants';
import { useQueryClient } from 'react-query';

type UserContextProps = {
  userCaseStudies: CaseStudyInfo[] | null
  userCurrentBulkDownloads: Array<number>
  updateUserCaseStudies: () => Promise<void>
  addCaseStudyToBulkDownloads: (caseStudyID: number[]) => Promise<boolean>
  removeCaseStudyFromBulkDownloads: (caseStudyID: number) => Promise<boolean>,
  resetBulkDownloads: () => Promise<void>,
  userLastSavedSearchRun: Date,
  recordUserLastSavedSearchRun: () => void
}

const initUserContextPropsState = {
  userCaseStudies: null,
  userCurrentBulkDownloads: [],
  updateUserCaseStudies: async () => { },
  addCaseStudyToBulkDownloads: async (caseStudyID: number[]) => { return true },
  removeCaseStudyFromBulkDownloads: async (caseStudyID: number) => { return true },
  resetBulkDownloads: async () => { },
  userLastSavedSearchRun: new Date(),
  recordUserLastSavedSearchRun: () => { },
}

const UserContext = createContext<UserContextProps>(initUserContextPropsState)

const useUserContext = () => {
  return useContext(UserContext)
}

const UserProvider: FC<WithChildren> = ({ children }) => {

  const queryClient = useQueryClient()
  const [userCaseStudies, setUserCaseStudies] = useState<CaseStudyInfo[] | null>(null)

  const [userCurrentBulkDownloads, setUserCurrentBulkDownloads] = useState<Array<number>>([])
  const [userLastSavedSearchRun, setUserLastSavedSearchRun] = useState<Date>(new Date())
  const { getApiConfiguration } = useAuthContext()

  const updateUserCaseStudies = async () => {
    var caseStudiesApi = new CaseStudiesApi(await getApiConfiguration())
    var caseStudies = await caseStudiesApi.apiCaseStudiesMeGet()
    setUserCaseStudies(caseStudies)
  }

  const getInitialUserBulkDownloads = async () => {
    var bulkDownloadsApi = new BulkDownloadsApi(getApiConfiguration());
    var collection = await bulkDownloadsApi.apiBulkDownloadsMeActiveGet();
    setUserCurrentBulkDownloads(collection?.caseStudies?.map(cs => cs.id ?? 0) ?? [])
  }

  const addCaseStudyToBulkDownloads = async (caseStudies: number[]) => {

    var bulkDownloadsApi = new BulkDownloadsApi(getApiConfiguration());
    var caseStudiesAdded = await bulkDownloadsApi.apiBulkDownloadsAddPost(caseStudies)

    var updatedCaseStudies = [...userCurrentBulkDownloads].concat(caseStudiesAdded);
    setUserCurrentBulkDownloads(updatedCaseStudies)
    queryClient.invalidateQueries(Queries.UserActiveCollection)
    return true;
  }

  const removeCaseStudyFromBulkDownloads = async (caseStudyID: number) => {

    if (userCurrentBulkDownloads.indexOf(caseStudyID) == -1)
      return false;

    var bulkDownloadsApi = new BulkDownloadsApi(getApiConfiguration());
    var caseStudyRemoved = await bulkDownloadsApi.apiBulkDownloadsRemovePost(caseStudyID)

    if (caseStudyRemoved == caseStudyID) {
      var index = userCurrentBulkDownloads.indexOf(caseStudyRemoved);
      if (index !== -1) {
        userCurrentBulkDownloads.splice(index, 1);
      }
      setUserCurrentBulkDownloads(userCurrentBulkDownloads.filter(function (caseStudy) {
        return caseStudy !== caseStudyID
      }));
      queryClient.invalidateQueries(Queries.UserActiveCollection)

      return true;
    }

    return false;
  }

  const resetBulkDownloads = async () => {
    setUserCurrentBulkDownloads([])
  }

  const recordUserLastSavedSearchRun = () => {
    setUserLastSavedSearchRun(new Date())
  }

  useEffect(() => {
    (async () => {
      await updateUserCaseStudies()
      await getInitialUserBulkDownloads()
    })();
  }, [])

  return (
    <UserContext.Provider value={{ userCaseStudies, userCurrentBulkDownloads, updateUserCaseStudies, addCaseStudyToBulkDownloads, removeCaseStudyFromBulkDownloads, resetBulkDownloads, userLastSavedSearchRun, recordUserLastSavedSearchRun }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserProvider, useUserContext }
