import { AwardsApi, NewsApi, PagesApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { useQuery, useQueryClient } from "react-query"
import { Queries, Urls } from "../../Constants";
import { AwardJudgingPanel } from "../../modules/awards/AwardJudgingPanel";
import { AwardSubmissionCard } from "../../modules/awards/AwardSubmissionCard";
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard";
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { ErrorComponent } from "../../modules/core/ErrorComponent";
import { useState } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataHelpers } from "../../helpers/dataHelpers";
import { LatestNewsPanel } from "../../modules/news/LatestNewsPanel";
import { Helmet } from "react-helmet";

function Page() {

    var navigate = useNavigate();
    var params = useParams();
    var slug = params.slug ?? "";
    const { getApiConfiguration } = useAuthContext()
    const pagesApi = new PagesApi(getApiConfiguration())


    const fetchPage = async () => {
        var page = await pagesApi.apiPagesSlugSlugGet(slug);

        if (!page)
            navigate(Urls.NotFound)

        return page
    }

    var { data: page, status: pageStatus } = useQuery(Queries.Page + slug, fetchPage)

    return <>
        <Helmet>
            <title>{page?.title}</title>
        </Helmet>
        {pageStatus === "error" && <ErrorComponent></ErrorComponent>}
        {pageStatus === "loading" && <LoadingComponent height='90vh'></LoadingComponent>}
        {pageStatus === "success" && (
            <>
                <section className="pageheading">
                    <div className="colour-tertiary clearfix">
                        <div className="container">
                            <h1 className="left">{page?.title}</h1>
                        </div>
                    </div>
                </section>
                <div className="container bodycopy">
                    <article className="newsarticle">
                        <p dangerouslySetInnerHTML={{ __html: DataHelpers.convertToHtml(page?.content) }}></p>
                    </article>
                </div>
            </>
        )}
    </>
}

export { Page }

