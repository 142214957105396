
import { useEffect, useState } from "react";
import { AwardsApi, CaseStudyAwardInfo, useAuthContext } from "@pull/pull-groupm-csp-api";
import { CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import { IEditCaseStudyStepProps, useCaseStudyEditContext } from "./_CaseStudyEditContainer";
import $ from 'jquery'
import { useQuery, useQueryClient } from "react-query";
import { Queries } from "../../../Constants";
import { ErrorComponent } from "../../../modules/core/ErrorComponent";
import { Formik } from "formik";

export function CaseStudyEditAwards(props: IEditCaseStudyStepProps) {

    const queryClient = useQueryClient();
    const { caseStudy, fetching, updateContextCaseStudy } = useCaseStudyEditContext();
    const { getApiConfiguration } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration())
    const [caseStudyPreviousAwards, setCaseStudyPreviousAwards] = useState<Array<CaseStudyAwardInfo>>([])

    async function removeAwardSubmission(submissionID?: number) {
        if (submissionID) {
            await awardsApi.apiAwardsAwardSubmissionsIdDelete(submissionID)
            updateContextCaseStudy()
            queryClient.invalidateQueries(Queries.OpenAwards)
        }
    }

    async function addAwardSubmission(awardID?: number) {
        if (awardID) {
            await awardsApi.apiAwardsAwardSubmissionsPost({
                awardID,
                caseStudyID: caseStudy?.id,
            })
            updateContextCaseStudy()
            queryClient.invalidateQueries(Queries.OpenAwards)
        }
    }

    async function getOpenAwards() {
        return await awardsApi.apiAwardsAwardsSubmissionsOpenGet()
    }

    const { data: openAwards, status } = useQuery(Queries.OpenAwards, getOpenAwards, { refetchOnWindowFocus: false });

    useEffect(() => {
        var openAwardIDs = openAwards?.map(a => a.id) ?? [];
        setCaseStudyPreviousAwards(caseStudy?.awards?.filter(a => !openAwardIDs.includes(a.awardID)) ?? [])
        redrawMaterialize()
    }, [caseStudy, openAwards, fetching]);

    async function redrawMaterialize() {
        setTimeout(() => {
            $('select.materialize').each((index, item) => {
                M.FormSelect.init(item)
            })
        }, 250)
    }

    async function progressStep(draft: boolean) {
        props.onComplete(CaseStudyStep.Awards, draft)
    }

    return (
        <>
            {status === "error" && <ErrorComponent></ErrorComponent>}
            {(status === "loading" || fetching) && <LoadingComponent loadingAnimationDelay={0}></LoadingComponent>}
            {status === "success" && caseStudy && (
                <div className="container bodycopy material">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            selectedAwards: caseStudy?.awards?.map(a => a.awardID?.toString()) ?? [],
                        }}
                        onSubmit={async (values) => {
                            var openAwardIDs = openAwards?.map(a => a.id) ?? [];
                            var caseStudyAwardIDs = caseStudy.awards?.map(a => a.awardID) ?? [];

                            for (var openAwardID of openAwardIDs) {
                                if (values.selectedAwards.includes(openAwardID?.toString()) && !caseStudyAwardIDs.includes(openAwardID)) {
                                    await addAwardSubmission(openAwardID)
                                }
                                else if (!values.selectedAwards.includes(openAwardID?.toString()) && caseStudyAwardIDs.includes(openAwardID)) {
                                    var submissionID = caseStudy?.awards?.filter(a => a.awardID == openAwardID)[0].submissionID;
                                    await removeAwardSubmission(submissionID)
                                }
                            }
                            await progressStep(false);
                        }}
                        validate={async () => {
                            setTimeout(redrawMaterialize, 1)
                            window.scrollTo(0, 0);
                            return true
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ isSubmitting, handleSubmit, isValid, values, getFieldProps, errors, setFieldValue }) => (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <div className="row clearfix">
                                        <div className="col s12">
                                            <h3>Awards for {caseStudy?.title}</h3>
                                            <div className="row clearfix">
                                                <div className="col s12">
                                                    <div className="shadowbox clearfix">
                                                        <div className="row clearfix">

                                                            {openAwards.length > 0 ?
                                                                <div className="input-field col s12">
                                                                    <p>Choose award(s) to enter.  If you select an award by mistake you can just unselect it again.</p>
                                                                    <ul className="checkbox-cards">
                                                                        {openAwards.map(award => {
                                                                            return (
                                                                                <li>
                                                                                    <label>
                                                                                        <input {...getFieldProps('selectedAwards')} type="checkbox" value={award.id} defaultChecked={values.selectedAwards.indexOf(award.id?.toString()) > -1} />
                                                                                        {award.categoryName} ({award.name})
                                                                                    </label>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div> :
                                                                <div className="input-field col s12">
                                                                    <p>There are no open awards at this time.</p>
                                                                </div>}
                                                        </div>
                                                        {caseStudyPreviousAwards.length > 0 ?
                                                            <div>
                                                                <details className="details-toggle colour-lightgrey">
                                                                    <summary>Previous Submission History</summary>
                                                                    <div>
                                                                        <ul>
                                                                            {caseStudyPreviousAwards.map(award => {
                                                                                return (
                                                                                    <li>
                                                                                        <dl>
                                                                                            <dt>Award</dt><dd>{award.awardName}</dd>
                                                                                            <dt>Status</dt>
                                                                                            {award.isWinner == null ?
                                                                                                <dd data-stage="in-progress">Judging in progress</dd>
                                                                                                : (award.isWinner ? <dd data-stage="winner">You won!</dd> : <dd data-stage="closed">Finished. (Did not win)</dd>)}

                                                                                        </dl>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </details>
                                                            </div> : ""}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="clearfix button-block">
                                        <button disabled={isSubmitting} type="submit" id="proceed" className="button right no3d">
                                            Save and Continue &nbsp; &gt;
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </Formik>
                </div>
            )
            }
        </>
    )
}
