import { CaseStudyInfo, CaseStudyOrder } from "@pull/pull-groupm-csp-api";
import { CaseStudyStep } from "../modules/case-studies/CaseStudyProgress";

export class CaseStudyHelpers {

    static isTextComplete(caseStudy?: CaseStudyInfo): boolean {
        if (!caseStudy)
            return false;

        if (caseStudy.title && caseStudy.fields?.challenge && caseStudy.fields.delivery && caseStudy.fields.idea && (caseStudy.isLegacy || caseStudy.fields.insight) && caseStudy.fields.results && (caseStudy.isLegacy || caseStudy.fields.summary))
            return true;
        else
            return false;
    }

    static isTagsComplete(caseStudy?: CaseStudyInfo): boolean {
        if (!caseStudy)
            return false;

        if (caseStudy.tags?.advertiser &&
            caseStudy.tags?.brand &&
            caseStudy.fields?.implementationDate &&
            (caseStudy.regions && caseStudy.regions.length > 0) &&
            (caseStudy.countries && caseStudy.countries.length > 0) &&
            (caseStudy.tags.sectors && caseStudy.tags.sectors.length > 0) &&
            (caseStudy.tags.channels && caseStudy.tags.channels.length > 0) &&
            (caseStudy.tags.audiences && caseStudy.tags.audiences.length > 0) &&
            (caseStudy.tags.objectives && caseStudy.tags.objectives.length > 0))
            return true;
        else
            return false;
    }

    static isImagesComplete(caseStudy?: CaseStudyInfo): boolean {
        if (!caseStudy)
            return false;

        if (caseStudy.images && caseStudy.images?.length > 0 && caseStudy.images[0].url)
            return true;
        else
            return false;
    }

    static isVideosComplete(caseStudy?: CaseStudyInfo): boolean {
        if (!caseStudy)
            return false;

        if (caseStudy.video && caseStudy.video.videoID)
            return true;
        else
            return false;
    }

    static isAwardsComplete(caseStudy?: CaseStudyInfo): boolean {
        if (!caseStudy)
            return false;

        if (caseStudy.awards && caseStudy.awards.length > 0)
            return true;
        else
            return false;
    }

    static isPublished(caseStudy?: CaseStudyInfo): boolean {
        if (!caseStudy)
            return false;

        return caseStudy.published != null;
    }

    static getNextStepUrl(currentStep: CaseStudyStep, id: number) {
        switch (currentStep) {
            case CaseStudyStep.Text:
                return `/casestudies/${id}/edittags`
            case CaseStudyStep.Tags:
                return `/casestudies/${id}/editimages`
            case CaseStudyStep.Images:
                return `/casestudies/${id}/editvideo`
            case CaseStudyStep.Video:
                return `/casestudies/${id}/editawards`
            case CaseStudyStep.Awards:
                return `/casestudies/${id}/publish`
        }
        return `/casestudies/${id}`
    }

    static getPrimaryImageUrl(caseStudy?: CaseStudyInfo) {
        if (caseStudy && caseStudy.images && caseStudy.images?.length > 0)
            return caseStudy.images[0].url
        else
            return ""
    }

    static getAwards(caseStudy?: CaseStudyInfo) {
        var internalAwards = caseStudy?.awards?.filter(a => a.isWinner).map(a => a.awardName);
        var externalAwards = caseStudy?.externalAwards?.map(a => a.awardName);
        return internalAwards?.concat(externalAwards).join(", ")
    }

    static parseOrderEnum(order: string) {
        switch (order) {
            case "viewed":
                return CaseStudyOrder.Viewed
            case "downloaded":
                return CaseStudyOrder.Downloaded
            case "relevance":
                return CaseStudyOrder.Relevance
            case "recent":
            default:
                return CaseStudyOrder.Recent
        }
    }

}