import { AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { useQuery } from "react-query"
import { Queries } from "../../Constants";
import { AwardJudgingPanel } from "../../modules/awards/AwardJudgingPanel";
import { AwardSubmissionCard } from "../../modules/awards/AwardSubmissionCard";
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard";
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { ErrorComponent } from "../../modules/core/ErrorComponent";
import { Helmet } from "react-helmet";

function Judging() {

    const { getApiConfiguration } = useAuthContext()
    const awardsApi = new AwardsApi(getApiConfiguration())

    const getAwards = async () => {
        var awards = await awardsApi.apiAwardsAwardsJudgingOpenGet();
        return awards?.filter(a => a.submissions && a.submissions?.length > 0)
    }

    var { data: awards, status: awardsStatus } = useQuery(Queries.AwardJudging, getAwards, { refetchOnWindowFocus: false, refetchOnMount: true })

    return <>
        <Helmet>
            <title>Judging</title>
        </Helmet>
        <section className="pageheading">
            <div className="colour-tertiary clearfix">
                <div className="container">
                    <h1 className="left">Judging</h1>
                </div>
            </div>
        </section>
        <div className="container bodycopy">
            <p>
                This page is set up to facilitate our internal awards judging process. You'll find categories available to review below
            </p>
            {awardsStatus === "error" && <ErrorComponent></ErrorComponent>}
            {awardsStatus === "loading" && <LoadingComponent loadingAnimationDelay={0} height="100px"></LoadingComponent>}
            {awardsStatus === "success" && (
                <>
                    <div className="row clearfix bulkdownloads">
                        <div className="col s12">
                            {awards && awards?.length > 0 && awards?.map((award, index) => {
                                return (
                                    <>
                                        <AwardJudgingPanel award={award} isOpen={awards?.length == 1 && index == 0}></AwardJudgingPanel>
                                    </>)
                            })}
                            {(!awards || awards.length == 0) &&
                                <div className="alert normal">
                                    <ul>
                                        <li>There are no awards that are currently open for judging</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )}
        </div>
    </>
}

export { Judging }