import { useRef } from "react";
import { Link } from "react-router-dom";
import { CaseStudyInfo, Role, useAuthContext } from "@pull/pull-groupm-csp-api"
import { UserRoles } from "../../Constants";
import moment from 'moment'
import { BulkDownloadCollectionInfo } from "@pull/pull-groupm-csp-api";
import { useState } from "react";
import { CaseStudyCard } from "../case-studies/CaseStudyCard";

export function BulkDownloadCollectionCard(props: BulkDownloadCollectionCardProps) {

    const [open, setOpen] = useState(false)

    function toggleDetails(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        setOpen(!open)
    }

    return (
        <div className="row clearfix">
            <div className="col s12 judgingbox">
                <div className={ "shadowbox clearfix " + (!open ? "hide" : "")}>
                    <div className="clearfix">
                        <h3 className="left">{props.collection.name}</h3>
                        <a href={props.collection.downloadLink} className="button blue no3d right" target="_blank">Download Again</a>
                        <span className="moredetails relative right" onClick={toggleDetails}>MORE DETAILS</span>
                    </div>
                    <div className="shadowinner">
                        <div className="searchresults clearfix list">
                            <span className="clearfix block">{props.collection.caseStudies?.length} ENTRIES</span>
                            <br />
                            { props.collection.caseStudies?.map(cs => {
                                return <CaseStudyCard key={cs.id} caseStudy={cs}></CaseStudyCard>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface BulkDownloadCollectionCardProps {
    collection: BulkDownloadCollectionInfo
}