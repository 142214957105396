import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'
import { useAuthContext, SettingInfo, SettingsApi, TagCollectionInfo, TagsApi, CountryInfo, RegionInfo, AwardCategoryInfo, AwardsApi, PagesApi, PageInfo } from '@pull/pull-groupm-csp-api';
import { WithChildren } from '../../helpers/react18MigrationHelpers';
import { LoadingComponent } from './LoadingComponent';
import { useQuery } from 'react-query';
import { Queries } from '../../Constants';

type GlobalContextProps = {
  getSetting: (key: string) => any;
  tags: TagCollectionInfo | null;
  countries: CountryInfo[],
  regions: RegionInfo[],
  awardCategories: AwardCategoryInfo[]
  supportPages: PageInfo[]
}

const initGlobalContextPropsState = {
  getSetting: () => null,
  tags: null,
  countries: [],
  regions: [],
  awardCategories: [],
  supportPages: []
}

const GlobalContext = createContext<GlobalContextProps>(initGlobalContextPropsState)

const useGlobalContext = () => {
  return useContext(GlobalContext)
}

const GlobalProvider: FC<WithChildren> = ({ children }) => {

  const [appSettings, setAppSettings] = useState<SettingInfo[] | null>(null)
  const [tags, setTags] = useState<TagCollectionInfo>({})
  const [countries, setCountries] = useState<CountryInfo[]>([])
  const [regions, setRegions] = useState<RegionInfo[]>([])
  const [supportPages, setSupportPages] = useState<PageInfo[]>([])
  const [awardCategories, setAwardCategories] = useState<AwardCategoryInfo[]>([])
  const { getApiConfiguration } = useAuthContext()

  const getSettingsInternal = async () => {
    var settingsApi = new SettingsApi(getApiConfiguration())
    var settings = await settingsApi.apiSettingsSystemGet(false)
    setAppSettings(settings)
  }

  const getTagsInternal = async () => {
    var tagsApi = new TagsApi(getApiConfiguration())
    var tags = await tagsApi.apiTagsGet()
    setTags(tags)
  }

  const getCountriesInternal = async () => {
    var settingsApi = new SettingsApi(getApiConfiguration())
    var countries = await settingsApi.apiSettingsCountriesGet()
    setCountries(countries)
  }

  const getRegionsInternal = async () => {
    var settingsApi = new SettingsApi(getApiConfiguration())
    var regions = await settingsApi.apiSettingsRegionsGet()
    setRegions(regions)
  }

  const getAwardCategories = async () => {
    var settingsApi = new AwardsApi(getApiConfiguration())
    var awardCategories = await settingsApi.apiAwardsAwardCategoriesGet()
    setAwardCategories(awardCategories)
  }

  const getSupportPages = async () => {
    var pagesApi = new PagesApi(getApiConfiguration())
    var supportPages = await pagesApi.apiPagesSearchPost({
      includeContent: false,
      onlyPublished: true,
      pageSize: 8
    })
    setSupportPages(supportPages.results ?? [])
  }

  const getSetting = (key: string) => {
    var items = appSettings?.filter(as => as.name == key);
    if (items && items?.length > 0)
      return items[0].value;
    return null;
  }

  async function getInitialSetup(): Promise<void> {
    await getSettingsInternal()
    await getTagsInternal()
    await getCountriesInternal()
    await getRegionsInternal()
    await getAwardCategories()
    await getSupportPages()
  }

  const { status } = useQuery(Queries.InitialSetup, getInitialSetup, { refetchOnWindowFocus: false });

  return (
    <GlobalContext.Provider value={{ getSetting, tags, countries, regions, awardCategories, supportPages }}>
      {status === "error" &&
        <section className="section-padding">
          <div className="container">
            <h2 className="section-heading">System error</h2>
            <p>An error has occured connecting to the Case Study Portal platform. Please try again later.</p>
          </div>
        </section>
      }
      {status === "loading" && <LoadingComponent useLogoAnimation={true} height='100vh' loadingAnimationDelay={0}></LoadingComponent>}
      {status === "success" && (
        <div>
          {children}
        </div>
      )}
    </GlobalContext.Provider>
  )
}

export { GlobalProvider, useGlobalContext }
