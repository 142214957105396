
import loadingAnimation from '../../assets/images/oval-purple.svg'
import { Tooltip } from '../../modules/general/ToolTip';
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CaseStudiesApi, CaseStudyImageInfo, CaseStudyInfo, MediaApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import React from "react";
import * as cropro from 'cropro';
import { css } from "jquery";
import { useCaseStudyEditContext } from "../../pages/case-studies/edit/_CaseStudyEditContainer";
import { CaseStudyWorkflowManager } from "../../managers/CaseStudyWorkflowManager";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { CaseStudyStep } from "./CaseStudyProgress";
import { LoadingComponent } from "../core/LoadingComponent";
import { DataHelpers } from "../../helpers/dataHelpers";
import { useUserContext } from "../user/UserContext";
import { Settings } from '../../Constants';
import { useGlobalContext } from '../core/GlobalContext';

export function CaseStudyImageUploader(props: CaseStudyImageUploaderProps) {

    const UPLOAD = "Your image is being uploaded. This may take a few seconds depending on the size of your image."
    const { getSetting } = useGlobalContext()
    const navigate = useNavigate();
    const { caseStudy, fetching } = useCaseStudyEditContext();
    const { getApiConfiguration } = useAuthContext();
    const imgRef = React.createRef<HTMLImageElement>();

    const { userCaseStudies } = useUserContext();
    const [desc, setDesc] = useState(props.caseStudyImage?.description ?? "")
    const [order, setOrder] = useState(props.caseStudyImage?.order ?? props.defaultOrder)
    const [showForm, setShowForm] = useState(props.caseStudyImage?.imageID || !props.allowEmpty)
    const [caseStudyImage, setCaseStudyImage] = useState(props.caseStudyImage)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [imagePreviewLoading, setImagePreviewLoading] = useState(true)

    function showMarkerArea() {
        if (imgRef.current !== null) {
            setLoading(true)
            let cropArea = new cropro.CropArea(imgRef.current);
            cropArea.renderAtNaturalSize = true;
            cropArea.renderImageType = "image/jpeg";
            cropArea.renderImageQuality = 8;
            cropArea.displayMode = 'popup';

            cropArea.addRenderEventListener((imgURL) => {
                var blob = DataHelpers.b64toBlob(imgURL);
                uploadImage(blob);
            });

            cropArea.show();
            setLoading(false)
        }
    }

    async function showFormAction(e: React.FormEvent<HTMLAnchorElement>) {
        e.preventDefault()
        setShowForm(true);
    }

    async function hideFormAction(e: React.FormEvent<HTMLAnchorElement>) {
        e.preventDefault()
        var blankImage = {
            order: props.defaultOrder,
        };
        setCaseStudyImage(blankImage);
        setOrder(props.defaultOrder);
        setDesc("");
        props.onImageChange(props.index, blankImage)
        setShowForm(false);
        setError("")
    }

    async function changeHandler(event: any) {
        var file = event.target.files[0];
        await uploadImage(file)
    }

    async function uploadImage(file: any) {
        var mediaApi = new MediaApi(getApiConfiguration());

        try {
            setLoading(true)
            var result = await mediaApi.apiMediaImagePost(file, caseStudy?.id)
            setError("")

            if (result?.url) {

                var image = {
                    imageID: result.id ?? 0,
                    order: order,
                    description: desc,
                    url: result.url
                };

                setCaseStudyImage(image);
                setImagePreviewLoading(true);
                props.onImageChange(props.index, image)
            }

        }
        catch (ex) {
            var response = ex as Response
            if (response && response.status == 500 && response.headers) {
                var header = response.headers.get("content-type")
                if (header == "application/problem+json") {
                    var r = await response.json();
                    if (r.title)
                        setError(r.title)
                }
            }
        }
        setLoading(false)
    }

    function updateOrder(order: number) {
        setOrder(order)
        if (caseStudyImage)
            caseStudyImage.order = order;
        props.onImageChange(props.index, caseStudyImage)
    }

    function updateDescription(desc: string) {
        setDesc(desc)
        if (caseStudyImage)
            caseStudyImage.description = desc;
        props.onImageChange(props.index, caseStudyImage)
    }

    function imageLoadComplete(): void {
        setImagePreviewLoading(false)
    }

    return (
        !fetching && !loading ?
            <div>
                <div className="row clearfix" id="upload1">
                    <div className="file-field input-field col s12 withcreatelink">
                        <div className="shadowbox clearfix">
                            {showForm ?
                                <div className="shadowinner">
                                    <div className="btn"> <span>Browse File</span>
                                        <input id="casestudy_image1" name="casestudy[image1]" type="file" onChange={changeHandler} />
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" type="text" />
                                    </div>
                                    <div className="colour-lightgrey clearfix relative">
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyImageDescriptionTooltip)}></Tooltip>
                                                <input type="text" className="validate" placeholder="Please provide an image description" maxLength={30} value={desc} onChange={e => updateDescription(e.target.value)} />
                                                <label htmlFor="imagedesc" className="active">Image Description</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyImageOrderTooltip)}></Tooltip>
                                                <input type="number" className="validate" min={1} max={5} value={order} onChange={e => updateOrder(parseInt(e.target.value))} />
                                                <label htmlFor="imageorder" className="active">Image Order</label>
                                            </div>
                                        </div>
                                        {caseStudyImage?.url &&
                                            <div className="imagepreview absolute">
                                                <figure style={{ height: imagePreviewLoading ? "0" : "auto" }}>
                                                    <img ref={imgRef}
                                                        className={!imagePreviewLoading ? "preview" : ""}
                                                        src={`${window._env_.API_URL}/api/proxy?url=${caseStudyImage?.url}`}
                                                        crossOrigin=""
                                                        onClick={() => showMarkerArea()}
                                                        onLoad={() => imageLoadComplete()}
                                                    />
                                                </figure>
                                                {imagePreviewLoading &&
                                                    <img src={loadingAnimation} style={{ width: "50px", height: "50px" }} />
                                                }
                                            </div>
                                        }
                                    </div>
                                    {props.allowEmpty ? <>
                                        <a className="button no3d add minwidth" href="#" onClick={hideFormAction}>Remove</a>
                                    </> : ""}
                                    {error && <div className="alert error mb-0">
                                        <ul>
                                            <p>{error}</p></ul>
                                    </div>}

                                </div> : <a className="button no3d add minwidth" href="#" onClick={showFormAction}>Add Another +</a>}

                        </div>
                    </div>
                </div>

            </div >
            : <LoadingComponent text={UPLOAD} loadingAnimationDelay={0}></LoadingComponent>
    )
}


interface CaseStudyImageUploaderProps {
    index: number,
    caseStudyImage?: CaseStudyImageInfo,
    defaultOrder: number,
    allowEmpty: boolean,
    onImageChange: (index: number, image?: CaseStudyImageInfo) => void;
}

