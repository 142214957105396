import { AwardInfo, NewsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useState } from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { Queries } from "../../Constants"
import { ErrorComponent } from "../core/ErrorComponent"
import { LoadingComponent } from "../core/LoadingComponent"
import { NewsCard } from "./NewsCard"

function LatestNewsPanel() {

    const { getApiConfiguration } = useAuthContext()
    const newsApi = new NewsApi(getApiConfiguration())

    const fetchNews = async () => {
        return await newsApi.apiNewsSearchPost({
            pageSize: 4,
            page: 1,
            onlyPublished: true,
            includeContent: false
        });
    }

    var { data: news, status: newsStatus } = useQuery(Queries.LatestNewsArticles, fetchNews)

    return (
        <section className="colour-lightgrey news">
            <div className="container bodycopy">
                <div>
                    <h2>Latest News&nbsp; <Link to="/announcements">View All</Link></h2>
                </div>
                {newsStatus === "error" && <ErrorComponent></ErrorComponent>}
                {newsStatus === "loading" && <LoadingComponent></LoadingComponent>}
                {newsStatus === "success" && (
                    <>
                        <div className="clearfix footernews">
                            {news?.results?.map(news => {
                                return (<NewsCard key={news.id} news={news}></NewsCard>)
                            })}
                        </div>
                    </>
                )}
            </div>
        </section>
    )
}

export { LatestNewsPanel }