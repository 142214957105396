
import { useLoading } from './LoadingProvider';
import loadingAnimation from '../../assets/images/oval-purple.svg'
import logoLoadingAnimation from '../../assets/images/oval-purple-logo.svg'
import { useEffect, useState } from 'react';

const LoadingComponent = (props: LoadingComponentProps) => {

 const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(true)
    }, props.loadingAnimationDelay ?? 1000)
  })

  return (
      <div style={{height: props.height ?? 200}}> 
        <div className="loader" style={{display: showLoading ? "flex": "none"}}>
          <img width={props.useLogoAnimation ? 100 : 50} src={props.useLogoAnimation ? logoLoadingAnimation : loadingAnimation} />
          <p>{props.text}</p>
        </div>
      </div>)
}

interface LoadingComponentProps {
  height?: string
  loadingAnimationDelay?: number
  useLogoAnimation?: boolean,
  text?: string
}

export { LoadingComponent }