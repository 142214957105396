import { useEffect, useState } from 'react'
import { Route, Routes, Outlet, useSearchParams, Navigate } from 'react-router-dom'

import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useAuthContext } from '@pull/pull-groupm-csp-api';
import { Helmet } from 'react-helmet';


export function Maintenance() {

  return (
    <div>
        <div className="container">
          <header className="clearfix">
            <Link className='logo' to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="227.315" height="27.773" viewBox="0 0 227.315 27.773"><g fill="#9a3eff"><path d="M203.854 27.187c0-4.241-2.236-9.3-6.143-10.827a7.247 7.247 0 005.828-7.434c0-4.908-3.387-8.37-8.6-8.37h-12.1v26.632h5.326v-9.854a9.879 9.879 0 0110.319 9.854zm-9.293-14.953h-6.393v-6.58h6.393c2.242 0 3.652 1.179 3.652 3.271s-1.411 3.309-3.652 3.309zM180.254 27.187L169.411.555h-6.315l-10.765 26.632h5.832c2.015-4.516 7.665-6.9 13.272-7.406l2.972 7.406zm-18.2-9.755l4.219-10.522 3.182 7.935a22.39 22.39 0 00-7.4 2.588zM64.793.555v15.608c-3.253-5.641-9.58-12.667-12.2-15.608h-6.33v26.632h5.323V7.229c3.967 4.207 10.64 14.395 12.954 19.958h5.576V.555zM15.121 15.926c-5.779 0-9.8-15.371-9.8-15.371H0v26.632h5.326V13.222c1.709 4.11 5.57 8.011 9.8 8.011s8.127-3.9 9.833-8.055v14.008h5.321V.555h-5.323s-4.06 15.371-9.836 15.371zM35.647.555h5.326v26.632h-5.326zM75.48.555h10.272c7.569 0 12.745 5.9 12.745 13.353S93.32 27.186 85.752 27.186H75.48zm10.194 21.268c4.263 0 7.307-3.312 7.307-7.915s-3.044-7.99-7.307-7.99h-4.868v15.9zM208.258.555h19.06v5.1h-13.724v5.364h12.162v5.1h-12.162v5.972h13.724v5.1h-19.06zM112.574 10.522c-3.652-.38-4.678-1.291-4.678-2.891 0-1.484 1.749-2.495 4.338-2.495a5.085 5.085 0 014.715 3.468l4.99-2.2c-1.416-3.892-5.064-6.405-9.717-6.405-5.591 0-9.667 3.081-9.667 7.534 0 4.946 3.2 7.684 9.4 8.292 4.144.418 5.782 1.559 5.782 3.234 0 2.167-1.871 3.614-5.174 3.614-3.087 0-5.3-1.787-6.277-5.111l-4.942 1.908c1.369 5.142 5.432 8.3 11.227 8.3 6.39 0 10.5-3.614 10.5-8.866.002-5.161-3.727-7.696-10.497-8.382zM149.781.555h-5.337v14.033c-2.37-2.392-6.069-3.608-12.134-3.608V.555h-5.316v26.632h5.326V16.154c6.065 0 12.218 1.622 12.218 11.033h5.242z"></path></g></svg>
            </Link>
          </header>
        </div>
        <Helmet>
            <title>Scheduled Maintenance</title>
        </Helmet>
        <section className="pageheading">
          <div className="colour-tertiary clearfix">
            <div className="container">
              <h1 className="left">Scheduled Maintenance</h1>
            </div>
          </div>
        </section>
        <div className="container bodycopy login">
          <p>The Mindshare Case Studies portal is currently unavailable for scheduled maintenance. Please try again later.</p>
        </div>
        <footer className="colour-black">
            <div className="container clearfix">
              <div className="left">
                <a href="/terms-and-conditions">Terms &amp; Conditions</a> | © 2022 Mindshare
                <br />
                v{window._env_.VERSION ?? "4.0.0"}
              </div>
              <div className="right">
                <a href="#">Return to top</a>
                <br />
                Powered by <a href="http://www.thepullagency.com">The Pull Agency</a>
              </div>
            </div>
        </footer>
    </div>
  )
}
