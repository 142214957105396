import moment from "moment";
import { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AwardsApi, CaseStudiesApi, useAuthContext, AwardSubmissionInfo, Picture, ImageHelpers } from "@pull/pull-groupm-csp-api";
import { CaseStudyActionBar } from "../../modules/case-studies/CaseStudyActionBar";
import Slider from "react-slick";
import trophy from '../../assets/images/trophy.png'
import '../../assets/css/slick.css'
import '../../assets/css/slick-theme.css'
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { Queries, Urls } from "../../Constants";
import { useQuery } from "react-query";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { DataHelpers } from "../../helpers/dataHelpers";
import { ErrorComponent } from "../../modules/core/ErrorComponent";
import { Helmet } from "react-helmet";
import { data } from "jquery";

export function Award(props: AwardProps) {

    const { getApiConfiguration, currentSession } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration())
    const [primaryWinner, setPrimaryWinner] = useState<AwardSubmissionInfo>()
    const [secondaryWinners, setSecondaryWinners] = useState<AwardSubmissionInfo[]>()
    const [latestEntries, setLatestEntries] = useState<AwardSubmissionInfo[]>()

    const getAwardCategory = async () => {
        return await awardsApi.apiAwardsAwardCategoriesIdGet(props.awardCategoryID)
    }

    const getWinners = async () => {
        var winners = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
            winningSubmissionsOnly: true,
            selectedAwardCategories: [props.awardCategoryID],
            page: 1,
            pageSize: 4,
            columns: {
                caseStudy: {
                    includeImages: true,
                    includeAdvertiser: true,
                    includeCountries: true
                }
            }
        })

        if (winners.results && winners.results?.length > 0) {
            setPrimaryWinner(winners.results[0])
            setSecondaryWinners(winners.results.slice(1))
        }
    }

    const getLatestEntries = async () => {
        var entries = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
            recentSubmissionsOnly: true,
            selectedAwardCategories: [props.awardCategoryID],
            page: 1,
            pageSize: 6,
            columns: {
                caseStudy: {
                    includeImages: true,
                    includeBrand: true,
                    includeCountries: true
                }
            }
        })

        if (entries.results && entries.results?.length > 0)
            setLatestEntries(entries.results)
    }

    const { data: awardCategory, status: awardCategoryStatus } = useQuery(Queries.AwardCats + props.awardCategoryID, getAwardCategory, { refetchOnWindowFocus: false });
    const { status: winnersStatus } = useQuery(Queries.AwardWinners + props.awardCategoryID, getWinners, { refetchOnWindowFocus: false });
    const { status: latestEntriesStatus } = useQuery(Queries.AwardEntries + props.awardCategoryID, getLatestEntries, { refetchOnWindowFocus: false });

    return (
        <div>
            <Helmet>
                <title>{awardCategory?.name}</title>
            </Helmet>
            {winnersStatus === "error" && <ErrorComponent></ErrorComponent>}
            {(winnersStatus === "loading" || !primaryWinner) && <LoadingComponent height="1000px"></LoadingComponent>}
            {winnersStatus === "success" && primaryWinner && (
                <>
                    <section className="hero" style={{ backgroundImage: `url('${ImageHelpers.getImageUrl(CaseStudyHelpers.getPrimaryImageUrl(primaryWinner.caseStudy), undefined, 1200)}')` }}>
                        <div className="container">
                            <div className="author cotm colour-primary alpha absolute active">
                                <div className="clearfix">
                                    <div className="left">
                                        <div className="imageblock">
                                            <div className="views">
                                                <figure className="icon">
                                                    <img alt="" src={trophy} />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="headings left">
                                            <em className="tag">{primaryWinner.awardName} Winner</em>
                                            <h1>{primaryWinner.caseStudy?.title}</h1>
                                        </div>
                                    </div>
                                    <div className="left authorinfo">
                                        <p>{primaryWinner.caseStudy?.fields?.challenge && primaryWinner.caseStudy?.fields?.challenge?.length > 150
                                            ? primaryWinner.caseStudy?.fields?.challenge.substring(0, 150) + "..." : primaryWinner.caseStudy?.fields?.challenge}</p>
                                        <Link className="button" to={"/casestudies/" + primaryWinner.caseStudy?.id}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>)}
            <div className="container bodycopy">
                {awardCategoryStatus === "error" && <ErrorComponent></ErrorComponent>}
                {awardCategoryStatus === "loading" && <LoadingComponent></LoadingComponent>}
                {awardCategoryStatus === "success" && (
                    <>
                        <div className="clearfix">
                            <div className="justified">
                                <h2>What is {awardCategory.name}?</h2>
                            </div>
                        </div>
                        <article className="">
                            <div className="justified">
                                <p dangerouslySetInnerHTML={{ __html: DataHelpers.convertToHtml(awardCategory.description) }}></p>
                                <Link className="button" to={Urls.CaseStudiesCreate}>Create Case Study</Link>
                            </div>
                        </article>
                    </>
                )}
                {latestEntriesStatus === "error" && <ErrorComponent></ErrorComponent>}
                {latestEntriesStatus === "loading" && <LoadingComponent></LoadingComponent>}
                {latestEntriesStatus === "success" && (
                    <>
                        <section className="morecasestudies">
                            <div className="clearfix">
                                <div className="justified">
                                    <hr />
                                    <h2>Latest Entries</h2>
                                </div>
                            </div>
                            <div className="casestudieswrap cotm-page clearfix">
                                {latestEntries?.map(entry => {
                                    return (
                                        entry.caseStudy ?
                                            <div key={entry.caseStudy.id} className="left">
                                                <div className="box small relative">
                                                    <div className="imgwrap absolute">
                                                        <Picture url={CaseStudyHelpers.getPrimaryImageUrl(entry.caseStudy)} className="absolute" alt="#" height={400} />
                                                    </div>
                                                    <div className="author colour-primary alpha absolute">
                                                        <h4 className="title">{entry.caseStudy?.title}</h4>
                                                    </div>
                                                    <Link to={`/casestudies/` + entry.caseStudy?.id} title={entry.caseStudy.title}>
                                                        {entry.caseStudy?.title}
                                                    </Link>
                                                </div>
                                                <br />
                                                <ul className="authorinfo">
                                                    <li><strong>COUNTRY: </strong> {entry.caseStudy.countries?.map(c => c.name).join(",")}</li>
                                                    <li><strong>BRAND: </strong>{entry.caseStudy.tags?.brand?.value}</li>
                                                </ul>
                                            </div> : ""
                                    )
                                })}

                            </div>
                        </section>
                    </>
                )}
            </div>
            {winnersStatus === "error" && <ErrorComponent></ErrorComponent>}
            {winnersStatus === "loading" && <LoadingComponent></LoadingComponent>}
            {winnersStatus === "success" && secondaryWinners && (
                <>
                    <section className="colour-primary morecasestudies">
                        <div className="container bodycopy clearfix">
                            <h2>
                                Previous Winners
                            </h2>
                            <div className="casestudieswrap clearfix">
                                {secondaryWinners?.length > 0 ? "" : ""}
                                <div className="slider left">
                                    <div className="slide box relative">
                                        <div className="pill-container" data-position="floating">
                                            <span className="pill pill-dark">{secondaryWinners[0].awardName} Winner</span>
                                        </div>
                                        <div className="imgwrap absolute">
                                            <Picture url={CaseStudyHelpers.getPrimaryImageUrl(secondaryWinners[0].caseStudy)} className="absolute" alt="#" height={500} />
                                        </div>
                                        <div className="author colour-primary alpha absolute">
                                            <div className="clearfix">
                                                <div className="left">
                                                    <div className="imageblock">
                                                        <div className="views relative">
                                                            <div>
                                                                <strong>{secondaryWinners[0].caseStudy?.viewCount}</strong>views
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="headings left">
                                                        <h4>{secondaryWinners[0].caseStudy?.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="left authorinfo">
                                                    <ul>
                                                        <li><strong>Advertiser: </strong> <span>{secondaryWinners[0].caseStudy?.tags?.advertiser?.value}</span> </li>
                                                        <li><strong>Implementation Date: </strong><span>{moment(secondaryWinners[0].caseStudy?.fields?.implementationDate).format("yyyy")}</span></li>
                                                        <li>
                                                            <strong>
                                                                Country:
                                                            </strong>
                                                            {secondaryWinners[0].caseStudy?.countries?.map(c => c.name).join(",")}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={`/casestudies/` + secondaryWinners[0].caseStudy?.id} title={secondaryWinners[0].caseStudy?.title}>
                                            {secondaryWinners[0].caseStudy?.title}
                                        </Link>
                                    </div>
                                </div>
                                {secondaryWinners.slice(1).map(entry => {
                                    return (
                                        <div className="left" key={entry.id}>
                                            <div className="box small relative">
                                                <div className="pill-container" data-position="floating">
                                                    <span className="pill pill-dark">{entry.awardName} Winner</span>
                                                </div>
                                                <div className="imgwrap absolute">
                                                    <img src={CaseStudyHelpers.getPrimaryImageUrl(entry.caseStudy)} className="absolute" alt="#" />
                                                </div>
                                                <div className="author colour-primary alpha absolute">
                                                    <h4 className="title">{entry.caseStudy?.title}</h4>
                                                    <a href="#" title="...">
                                                        {entry.caseStudy?.title}
                                                    </a>
                                                </div>
                                                <Link to={`/casestudies/` + entry.caseStudy?.id} title={entry.caseStudy?.title}>
                                                    {entry.caseStudy?.title}
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </section>
                </>)}
        </div >


    )
}

interface AwardProps {
    awardCategoryID: number
}

