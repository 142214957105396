
import { CaseStudyInfo, Picture } from "@pull/pull-groupm-csp-api"
import { Link } from "react-router-dom"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useState } from "react";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";

function CaseStudySlider(props: CaseStudySliderProps) {

    const [swiper, setSwiper] = useState<SwiperClass>();

    return (
        <>
            {
                props.caseStudies.length > 0 &&
                <div className="campaign-entries">
                    <h3 className="campaign-entries_subhead">{props.title}</h3>
                    <Swiper
                        className="campaign-entries_wrapper"
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={20}
                        slidesPerView={2}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        breakpoints= {{
                            768: {
                                spaceBetween: 32
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 32
                            }
                        }}
                        loop={false}
                        onSwiper={setSwiper}
                    >
                        {props.caseStudies.map(cs =>
                            cs ?
                                <SwiperSlide key={cs.id} className="swiper-slide">
                                    <Link to={`/casestudies/` + cs.id} className="campaign--img">
                                        <div>
                                            <figure>
                                                <picture>
                                                    <Picture className='hero-img' url={CaseStudyHelpers.getPrimaryImageUrl(cs)} height={400} lazy={true}></Picture>
                                                </picture>
                                            </figure>
                                            <div className="campaign--views">
                                                <span>{cs.viewCount}</span>
                                                <span>Views</span>
                                            </div>
                                        </div>
                                        <h4 className="campaign--title">
                                            {cs.title}
                                        </h4>
                                    </Link>
                                </SwiperSlide> : ""
                        )}
                    </Swiper>
                    {props.caseStudies.length > 3 && <div className="swiper-button-next" onClick={() => swiper?.slideNext()}></div>}
                    {props.caseStudies.length > 3 && <div className="swiper-button-prev" onClick={() => swiper?.slidePrev()}></div>}
                </div>
            }
        </>
    )
}

interface CaseStudySliderProps {
    title: string,
    caseStudies: (CaseStudyInfo | undefined)[]
}


export { CaseStudySlider }