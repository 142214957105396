import { AwardInfo } from "@pull/pull-groupm-csp-api"
import { useState } from "react"
import { AwardSubmissionCard } from "./AwardSubmissionCard"

function AwardJudgingPanel(props: AwardJudgingPanelProps) {

    const [open, setOpen] = useState(props.isOpen)

    return (
        <div className="row clearfix">
            <div className="col s12 judgingbox">
                <div className={"shadowbox clearfix " + (!open ? "hide" : "")}>
                    <div className="clearfix">
                        <h3 className="left">{props.award.categoryName} - {props.award.name}</h3>
                        <span onClick={() => setOpen(!open)} className="moredetails relative right">MORE DETAILS</span>
                    </div>
                    <div className="shadowinner">
                        <div className="searchresults clearfix list">
                            <span className="clearfix block">{props.award.submissions?.length} ENTRIES</span>
                            <br />
                            <>
                                {props.award.submissions?.map(submission => {
                                    return <AwardSubmissionCard awardSubmission={submission} />
                                })}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


interface AwardJudgingPanelProps {
    award: AwardInfo,
    isOpen:boolean
}


export { AwardJudgingPanel }