import { useEffect, useState } from 'react'
import { Route, Routes, Outlet, useSearchParams, Navigate } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useAuthContext } from '@pull/pull-groupm-csp-api';
import { useFormik } from 'formik';
import spinner from '../../assets/images/tail-spin.svg'
import userManager from '../../modules/auth/user-manager';
import { Helmet } from 'react-helmet';


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  password: Yup.string()
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {

  const { currentSession } = useAuthContext()

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { login } = useAuthContext()

  useEffect(() => {
    (async () => {
      if (currentSession) {
        navigate("/")
      }
    })();
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {

        await login(values.email, values.password)

        var returnUrl = searchParams.get("returnUrl");

        if (returnUrl)
          window.location.href = returnUrl;
        else
          window.location.href = "/"


      } catch (error) {
        console.error(error)
        setStatus('Invalid email or password')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const onInternalLogin = () => {

    var returnUrl = searchParams.get("returnUrl");
    var redirectUri = userManager.settings.redirect_uri;

    if (returnUrl)
      redirectUri += "?returnUrl=" + returnUrl;

    userManager.signinRedirect({
      redirect_uri: redirectUri
    });
  }

  return (
    <div>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <section className="pageheading">
        <div className="colour-tertiary clearfix">
          <div className="container">
            <h1 className="left">Sign In</h1>
          </div>
        </div>
      </section>
      <div className="container bodycopy login">
        <div className="twocol-parent">
          <div>
            <h2>Internal</h2>
            <p>
              If you have any of the following email addresses, click the button below to log in:
              <br />
              <ul>
                <li>@mindshareworld.com</li>
                <li>@neomediaworld.com</li>
              </ul>
              <br /><br /><br />
              <button onClick={onInternalLogin} className="button no3d">Sign in</button>
            </p>
            <br />
          </div>
          <div id="login">
            <h2>External</h2>
            <p>
              Otherwise, please use the sign in form below<br /><br />
              If you do not have login details please get in touch with <a href="mailto:elena.livshits@mindshareworld.com">elena.livshits@mindshareworld.com</a>
            </p>
            {formik.status ? (
              <div className=''>
                <div className='error'>{formik.status}</div>
                <p></p>
              </div>
            ) : ""}
            <div className="login-left">
              <form onSubmit={formik.handleSubmit} noValidate id="loginForm">
                <div className="material">
                  <div className="row clearfix">
                    <div className="input-field col s12">
                      <label className="active">Email</label><br />
                      <input {...formik.getFieldProps('email')} type="email" autoComplete="off" /></div>
                    {formik.touched.email && formik.errors.email && (
                      <div className='col s12 error'>{formik.errors.email}</div>
                    )}
                  </div>
                  <div className="row clearfix">
                    <div className="input-field col s12">
                      <label className="active" htmlFor="msuser_password">Password</label><br />
                      <input {...formik.getFieldProps('password')} type="password" autoComplete="off" /></div>
                    {formik.touched.password && formik.errors.password && (
                      <div className='col s12 error'>{formik.errors.password}</div>
                    )}
                  </div>
                </div>
                <div>
                  <button disabled={formik.isSubmitting || !formik.isValid} className="button btn cs4 no3d purple" type="submit">
                    {!loading && "Sign in"}
                    {loading && (
                      <img src={spinner} style={{ height: "25px" }} />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
