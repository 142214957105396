import { useEffect, useState } from 'react'
import { Route, Routes, Outlet, useSearchParams, Navigate } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useAuthContext } from '@pull/pull-groupm-csp-api';
import { useFormik } from 'formik';
import spinner from '../../../assets/images/tail-spin.svg'
import { OidcClient, UserManager } from "oidc-client-ts";
import userManager from '../../modules/auth/user-manager';
import { LoadingComponent } from '../../modules/core/LoadingComponent';

export function LoginExternalCallback() {

  const navigate = useNavigate();
  const { currentSession, loginExternal } = useAuthContext()
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    (async () => {
      if (currentSession) {
        navigate("/")
      } else {
        try {
          var user = await userManager.signinRedirectCallback(window.location.href);

          if (user && user.access_token) {
            await loginExternal(user.access_token)

            var returnUrl = searchParams.get("returnUrl");

            if (returnUrl)
              navigate(returnUrl)
            else
              navigate("/")
          }

        }
        catch { }

        setLoading(false)
      }
    })();
  })

  return (
    <div>
      <section className="pageheading">
        <div className="colour-tertiary clearfix">
          <div className="container">
            <h1 className="left">Sign In</h1>
          </div>
        </div>
      </section>
      <section>
        {loading ? <LoadingComponent></LoadingComponent> :
          <div className="container bodycopy material">
            <p className='error'>
              There has been an error signing you in. Please try again later.
            </p></div>
        }
      </section>
    </div>
  )
}
