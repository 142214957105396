import React, { useState } from 'react'
import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { MasterAuthenticatedLayout } from '../app/layout/MasterAuthenticatedLayout'
import { MasterAnonymousLayout } from '../app/layout/MasterAnonymousLayout'
import { AuthProvider, useAuthContext } from '@pull/pull-groupm-csp-api'
import { LoadingProvider } from './modules/core/LoadingProvider'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import {Helmet} from "react-helmet";

import $ from 'jquery'
import { GlobalProvider } from './modules/core/GlobalContext'
import ScrollToTop from './modules/core/ScrollToTop'
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from './insights/app-insights'
import ReactGA from 'react-ga4';

const queryClient = new QueryClient()

function App() {
  const { currentSession: session } = useAuthContext()
  usePageTracking();

  function initialiseAnalytics() {

    if(!window._env_.GA_TRACKING_ID)
      return false

    var testMode = window._env_.GA_TRACKING_TEST_MODE && JSON.parse(window._env_.GA_TRACKING_TEST_MODE) as boolean;

    ReactGA.initialize(window._env_.GA_TRACKING_ID, {
      testMode: testMode
    });

    return true;
  }
  
  function usePageTracking() {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
  
    useEffect(() => {
      if(initialiseAnalytics())
        setInitialized(true);
    }, []);
  
    useEffect(() => {
      if (initialized) {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
      }
    }, [initialized, location]);
  }

  return (
    <React.Suspense>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <LoadingProvider>
            <Helmet titleTemplate='%s - Mindshare Case Studies' defaultTitle='Mindshare Case Studies'>
            </Helmet>
            {session ? (
              <>
                <GlobalProvider>
                  <ScrollToTop />
                  <MasterAuthenticatedLayout />
                </GlobalProvider>
              </>
            ) : (
              <>
                <MasterAnonymousLayout />
              </>
            )}
          </LoadingProvider>
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </React.Suspense>
  )
}

export default App;
