
import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CaseStudiesApi, CaseStudyImageInfo, CaseStudyInfo, CaseStudyVideoInfo, MediaApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { CaseStudyProgress, CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import { CaseStudyEditContainer, IEditCaseStudyStepProps, useCaseStudyEditContext } from "./_CaseStudyEditContainer";
import React from "react";
import * as cropro from 'cropro';
import { css } from "jquery";
import { CaseStudyWorkflowManager } from "../../../managers/CaseStudyWorkflowManager";
import { CaseStudyHelpers } from "../../../helpers/caseStudyHelpers";
import { CaseStudyImageUploader } from "../../../modules/case-studies/CaseStudyImageUploader";
import { useUserContext } from "../../../modules/user/UserContext";

export function CaseStudyEditVideo(props: IEditCaseStudyStepProps) {

    const UPLOAD = "Your video is being uploaded. This may take a few minutes depending on the size of your video."

    const { caseStudy, fetching } = useCaseStudyEditContext();
    const { getApiConfiguration } = useAuthContext();
    const [caseStudyVideo, setCaseStudyVideo] = useState<CaseStudyVideoInfo>();
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState("")

    useEffect(() => {

        if (caseStudy?.video) {
            setCaseStudyVideo(caseStudy.video)
        }

    }, [caseStudy]);



    async function changeHandler(event: any) {
        var file = event.target.files[0];
        uploadVideo(file)
    }

    async function uploadVideo(file: any) {
        var apiConfig = await getApiConfiguration();
        var mediaApi = new MediaApi(apiConfig);

        try {
            setLoadingText(UPLOAD)
            setLoading(true)
            var result = await mediaApi.apiMediaVideoPost(file, caseStudy?.id)
            setError("")

            if (result?.url) {

                var video = {
                    videoID: result.id ?? 0,
                    url: result.url
                };

                setCaseStudyVideo(video);
            }
        }
        catch (ex) {
            var response = ex as Response
            if (response && response.status == 500 && response.headers) {
                var header = response.headers.get("content-type")
                if (header == "application/problem+json") {
                    var r = await response.json();
                    if (r.title)
                        setError(r.title)
                }
            }
        }
        setLoading(false)
        setLoadingText("")
    }

    async function saveVideo(draft: boolean) {

        var apiConfig = await getApiConfiguration();

        if (caseStudyVideo && caseStudy?.id) {
            try {
                setLoading(true)
                var workflowManager = new CaseStudyWorkflowManager(new CaseStudiesApi(apiConfig));
                await workflowManager.saveCaseStudyVideo(caseStudy?.id, caseStudyVideo.videoID ?? 0)
                setLoading(false)
                props.onComplete(CaseStudyStep.Video, draft)
            }
            catch (ex) {
                var response = ex as Response;
                var r = await response.json();
                console.log(r.title);
            }
        }

        props.onComplete(CaseStudyStep.Video, draft)
    }


    async function removeVideo(e: React.FormEvent<HTMLAnchorElement>) {
        e.preventDefault()
        setCaseStudyVideo({});
        setError("")
    }

    return (
        !fetching && !loading ?
            <div>
                <div className="container bodycopy material">

                    <div className="alert normal">
                        <ul>
                            <li>All uploaded videos must be less than 1GB in size and be an .mp4 file</li>
                        </ul>
                    </div>

                    {CaseStudyHelpers.isVideosComplete(caseStudy) ?
                        <div className="alert ok">
                            <p>This section is complete</p>
                        </div> :
                        <div className="alert error">
                            <p>This section is currently incomplete - Videos are not compulsory to upload. But if you do have one, browse and upload your video here.</p>
                        </div>}

                    <div>
                        {error ? <div className="alert error">
                            <ul>
                                <p>{error}</p></ul>
                        </div> : ""}

                        <div className="row clearfix" id="upload1">
                            <div className="file-field input-field col s12 withcreatelink">
                                <div className="shadowbox clearfix">
                                    <div className="shadowinner">
                                        <div className="btn"> <span>Browse File</span>
                                            <input id="casestudy_video" name="casestudy_video" type="file" onChange={changeHandler} />
                                        </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" disabled />
                                        </div>
                                        <div className="colour-lightgrey clearfix relative">
                                            {caseStudyVideo?.url ?
                                                <div className="video-container">
                                                    <video controls height="300" src={caseStudyVideo.url} width="550">
                                                    </video>
                                                </div> : ""}
                                        </div>
                                        {caseStudyVideo?.videoID ? <>
                                            <a className="button no3d add minwidth" href="#" onClick={removeVideo}>Remove</a>
                                        </> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix button-block">
                            <button  onClick={async () => await saveVideo(false)} type="submit" id="proceed" className="button right no3d">
                                Save and Continue &nbsp; &gt;
                            </button>
                            {!caseStudy?.published &&
                                <button type="submit" id="drafts" onClick={async () => await saveVideo(true)} className="back-button" name="drafts" formNoValidate>Save to drafts</button>
                            }
                        </div>
                    </div>
                </div>
            </div >
            : <LoadingComponent text={loadingText} loadingAnimationDelay={0}></LoadingComponent>
    )
}
