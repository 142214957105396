
import { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CaseStudiesApi, CaseStudyImageInfo, CaseStudyInfo, MediaApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { CaseStudyProgress, CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import { CaseStudyEditContainer, IEditCaseStudyStepProps, useCaseStudyEditContext } from "./_CaseStudyEditContainer";
import React from "react";
import * as cropro from 'cropro';
import { css } from "jquery";
import { CaseStudyWorkflowManager } from "../../../managers/CaseStudyWorkflowManager";
import { CaseStudyHelpers } from "../../../helpers/caseStudyHelpers";
import { CaseStudyImageUploader } from "../../../modules/case-studies/CaseStudyImageUploader";
import { useUserContext } from "../../../modules/user/UserContext";

export function CaseStudyEditImages(props: IEditCaseStudyStepProps) {

    const { caseStudy, fetching } = useCaseStudyEditContext();
    const { getApiConfiguration } = useAuthContext();
    const [caseStudyImages, setCaseStudyImages] = useState<Array<CaseStudyImageInfo>>([]);
    const [storedCaseStudyImages, setStoredCaseStudyImages] = useState<Array<CaseStudyImageInfo>>([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        if (caseStudy?.images) {
            for (var i = 0; i < 5; i++) {
                var caseStudyImage = i < caseStudy.images.length ? caseStudy.images[i] : { order: i + 1 };
                caseStudyImages.push(caseStudyImage);
                storedCaseStudyImages.push(caseStudyImage);
            }
            setCaseStudyImages(caseStudyImages)
            setCaseStudyImages(storedCaseStudyImages)
        }

    }, [caseStudy]);

    async function imageChange(index: number, caseStudyImage: any) {
        storedCaseStudyImages[index] = caseStudyImage;
        setStoredCaseStudyImages(storedCaseStudyImages);
    }

    async function saveImages(draft: boolean) {

        var apiConfig = await getApiConfiguration();

        if (storedCaseStudyImages && caseStudy?.id) {
            try {
                setLoading(true)
                var workflowManager = new CaseStudyWorkflowManager(new CaseStudiesApi(apiConfig));
                await workflowManager.saveCaseStudyImages(caseStudy?.id, storedCaseStudyImages)
                setLoading(false)
                props.onComplete(CaseStudyStep.Images, draft)
            }
            catch (ex) {
                var response = ex as Response;
                var r = await response.json();
                console.log(r.title);
            }
        }
    }



    return (
        !fetching && !loading ?
            <div>
                <div className="container bodycopy material">

                    <div className="alert normal">
                        <ul>
                            <li>All uploaded images must be at least 700px in width and height, be less than 10MB in size and be either a .jpg or .png</li>
                            <li> The image with the lowest order will be the primary image for your case study</li>
                            <li>Images can be cropped or rotated by clicking on the image after uploading</li>
                            <li>If you don't have any images to share you can use an image from our <a href="https://insidemedia.sharepoint.com/sites/MindshareBrandIdentityGuidelines/SitePages/Brand-Imagery.aspx" target="_blank">image library</a></li>
                        </ul>
                    </div>

                    {CaseStudyHelpers.isImagesComplete(caseStudy) ?
                        <div className="alert ok">
                            <p>This section is complete</p>
                        </div> :
                        <div className="alert error">
                            <p>This section is currently incomplete as at least one image must be provided</p>
                        </div>}

                    <div>
                        {caseStudyImages.map((csI, index) => {
                            return <CaseStudyImageUploader key={index} index={index} caseStudyImage={csI} defaultOrder={csI.order ?? index} allowEmpty={index != 0} onImageChange={imageChange} />
                        })}
                    </div>

                    <div className="clearfix button-block">
                        <button onClick={async () => await saveImages(false)} type="submit" id="proceed" className="button right no3d">
                            Save and Continue &nbsp; &gt;
                        </button>
                        {!caseStudy?.published &&
                            <button type="submit" id="drafts" onClick={async () => await saveImages(true)} className="back-button" name="drafts" formNoValidate>Save to drafts</button>
                        }
                    </div>
                </div>

            </div >
            : <LoadingComponent loadingAnimationDelay={0}></LoadingComponent>
    )
}
