import { createContext, FC, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CaseStudiesApi, CaseStudyInfo, Role, useAuthContext } from "@pull/pull-groupm-csp-api"
import { WithChildren } from "../../../helpers/react18MigrationHelpers"
import { CaseStudyProgress, CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress"
import { CaseStudyEditPublish } from "./CaseStudyEditPublish"
import { CaseStudyEditImages } from "./CaseStudyEditImages"
import { CaseStudyEditText } from "./CaseStudyEditText"
import { CaseStudyEditTags } from "./CaseStudyEditTags"
import { CaseStudyEditVideo } from "./CaseStudyEditVideo"
import { useUserContext } from "../../../modules/user/UserContext"
import { CaseStudyHelpers } from "../../../helpers/caseStudyHelpers"
import { CaseStudyEditAwards } from "./CaseStudyEditAwards"
import { Urls } from "../../../Constants"
import { Helmet } from "react-helmet"


type CaseStudyEditContextProps = {
    caseStudy: CaseStudyInfo | undefined
    updateContextCaseStudy: () => void,
    fetching: Boolean,
}


const initCaseStudyEditContextPropsState = {
    caseStudy: undefined,
    updateContextCaseStudy: () => {},
    fetching: false,
}

const useCaseStudyEditContext = () => {
    return useContext(CaseStudyContext)
}

const CaseStudyContext = createContext<CaseStudyEditContextProps>(initCaseStudyEditContextPropsState)

interface CaseStudyEditContainerProps {
    step: CaseStudyStep;
}

const CaseStudyEditContainer: FC<CaseStudyEditContainerProps> = (props: CaseStudyEditContainerProps) => {

    const navigate = useNavigate();
    const { updateUserCaseStudies } = useUserContext();
    const [caseStudy, setCaseStudy] = useState<CaseStudyInfo | undefined>()
    const [fetching, setFetching] = useState<Boolean>(true)
    const { getApiConfiguration, currentSession } = useAuthContext()


    var params = useParams();
    var id = params.id ? parseInt(params.id) : 0;


    const updateContextCaseStudy = async () => {
        if (id == 0) {
            setCaseStudy(undefined)
            setFetching(false);
            return;
        }

        try {
            var caseStudyApi = new CaseStudiesApi(getApiConfiguration());
            var caseStudy = await caseStudyApi.apiCaseStudiesIdIdGet(id)

            if(caseStudy.metadata?.createdByID != currentSession?.userID && currentSession?.role != Role.Administrator)
                navigate("/")

            setCaseStudy(await caseStudyApi.apiCaseStudiesIdIdGet(id))
        } catch (e) {
            console.log(e);
        }

        setFetching(false);
    }

    useEffect(() => {
        const getCaseStudy = async () => {
            await updateContextCaseStudy()
        };
        getCaseStudy();
    }, []);

    async function completeStep(currentStep:CaseStudyStep, isDraft:boolean, csId?:number){
        updateUserCaseStudies()

        if(isDraft)
            navigate(Urls.UserCaseStudiesDraft)
        else if(caseStudy){
            updateContextCaseStudy()
            navigate(CaseStudyHelpers.getNextStepUrl(currentStep, id))
        }
        else{
            navigate(CaseStudyHelpers.getNextStepUrl(currentStep, csId ?? 0))
        }
    }

    return (
        <CaseStudyContext.Provider value={{ caseStudy, updateContextCaseStudy, fetching }}>
            <Helmet>
                <title>{caseStudy ? "Edit" : "Create"} Case Study</title>
            </Helmet>
            <section className="pageheading">
                <div className="colour-tertiary clearfix">
                    <div className="container">
                        <h1 className="left">{caseStudy ? "Edit" : "Create"} Case Study</h1>
                    </div>
                </div>
            </section>
            <CaseStudyProgress step={props.step}></CaseStudyProgress>
            {props.step == CaseStudyStep.Text ? <CaseStudyEditText onComplete={completeStep} /> : ""}
            {props.step == CaseStudyStep.Tags ? <CaseStudyEditTags onComplete={completeStep} /> : ""}
            {props.step == CaseStudyStep.Images ? <CaseStudyEditImages onComplete={completeStep} /> : ""}
            {props.step == CaseStudyStep.Video ? <CaseStudyEditVideo onComplete={completeStep} /> : ""}
            {props.step == CaseStudyStep.Awards ? <CaseStudyEditAwards onComplete={completeStep} /> : ""}
            {props.step == CaseStudyStep.Publish ? <CaseStudyEditPublish onComplete={completeStep} /> : ""}
        </CaseStudyContext.Provider>
    )
}

export interface IEditCaseStudyStepProps{
    onComplete: (currentStep:CaseStudyStep, isDraft:boolean, id?:number) => void
}

export { CaseStudyEditContainer, useCaseStudyEditContext }