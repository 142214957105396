import { useEffect, useState } from "react"
import { BulkDownloadsApi, CaseStudyInfo, useAuthContext } from "@pull/pull-groupm-csp-api"
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard"
import { useUserContext } from "../../modules/user/UserContext"
import { Link } from "react-router-dom"
import { LoadingComponent } from "../../modules/core/LoadingComponent"
import animation from '../../assets/images/anim.gif'
import { useQuery, useQueryClient } from "react-query"
import { Queries, Urls } from "../../Constants"
import { ErrorComponent } from "../../modules/core/ErrorComponent"
import { Helmet } from "react-helmet"

export const BulkDownloads = () => {

    const queryClient = useQueryClient()
    const { getApiConfiguration } = useAuthContext()
    const { userCurrentBulkDownloads, resetBulkDownloads } = useUserContext()
    const [downloading, setDownloading] = useState(false)
    const [collectionName, setCollectionName] = useState("")
    const [downloadLink, setDownloadLink] = useState<string | null>(null)

    const getUserActiveBulkDownload = async () => {
        var bulkDownloadsApi = new BulkDownloadsApi(getApiConfiguration());
        var collection = await bulkDownloadsApi.apiBulkDownloadsMeActiveGet();
        setCollectionName(collection?.name ?? "")
        return collection
    }


    async function completeDownloadCollection() {
        var download = downloadLink;
        setDownloadLink(null)
        window.location.href = download ?? "";
    }

    const { data, status } = useQuery(Queries.UserActiveCollection, getUserActiveBulkDownload, { refetchOnWindowFocus: false });


    async function startDownloadCollection(event: React.MouseEvent<HTMLInputElement>) {
        event.preventDefault()
        var bulkDownloadsApi = new BulkDownloadsApi(getApiConfiguration())
        try {
            setDownloading(true)
            var result = await bulkDownloadsApi.apiBulkDownloadsDownloadPost({
                collectionID: data?.id,
                title: data?.name
            })
            resetBulkDownloads()
            setDownloadLink(result)
            queryClient.invalidateQueries(Queries.UserCollections)
            queryClient.invalidateQueries(Queries.UserActiveCollection)
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setDownloading(false)
        }
    }

    return (
        <div>
            <Helmet>
                <title>Bulk Downloads</title>
            </Helmet>
            <section className="pageheading">
                <div className="colour-tertiary clearfix">
                    <div className="container">
                        <h1 className="left">Bulk Downloads</h1>
                    </div>
                </div>
            </section>
            <section className="subnav colour-lightgrey">
                <div className="container clearfix">
                    <ul className="subnav relative left">
                        <div className="subnav-toggle">
                            <strong>Menu</strong>
                        </div>
                        <li className="active">
                            <Link to={Urls.UserBulkDownloads}>Downloads</Link>
                        </li>
                        <li className="">
                            <Link to={Urls.UserBulkDownloadsHistory}>Download History</Link>
                        </li>
                    </ul>
                </div>
            </section>
            {status === "error" && <ErrorComponent></ErrorComponent>}
            {status === "loading" && <LoadingComponent></LoadingComponent>}
            {status === "success" && (
                <>
                    <div className="container bodycopy material">
                        {data && (data.caseStudies && data.caseStudies?.length > 0) ?
                            <p>
                                You currently have {data?.caseStudies?.length ?? 0} case studies on your bulk download list. You can see them in the section below. To download just name your collection then click the download button.
                            </p> :
                            <p>
                                You don't have any case studies on your bulk download list.
                            </p>}
                        {data && data.caseStudies && data.caseStudies.length > 0 ?
                            <div className="row clearfix">
                                <form accept-charset="UTF-8" action="/bulkdownload/makepdf" className="validate-form" method="post" noValidate>

                                    <div className="row clearfix">
                                        <div className="col s12 judgingbox bulkdownload">
                                            <div className="shadowbox clearfix">
                                                <div className="clearfix">
                                                    <br />
                                                    <div className="input-field col s12 withinfo">
                                                        <div className="info">
                                                            <div className="infotext"> Name this collection</div>
                                                        </div>
                                                        <input onChange={(e) => { setCollectionName(e.target.value) }} value={collectionName} placeholder="Name your collection before downloading" required type="text" aria-invalid="true" />

                                                        <label htmlFor="Campaign_Title" className="active">Collection Name</label>
                                                    </div>
                                                    <input onClick={startDownloadCollection} type="submit" id="proceed" className="blue no3d right" value="Download Collection" />
                                                </div>
                                                <div className="shadowinner">
                                                    <div className="searchresults clearfix list">
                                                        <span className="clearfix block">{data.caseStudies.length} CASE STUDIES</span>
                                                        <br />
                                                        {data.caseStudies.map((caseStudy) => {
                                                            return <CaseStudyCard caseStudy={caseStudy} />
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div> : ""}
                    </div>
                </>)}
            <div className={"modal bulkdownload " + (!downloading && !downloadLink ? "hidden" : "")}>
                <div className={"box " + (!downloading ? "hidden" : "")}>
                    <div className="inner">
                        <h2>GENERATING YOUR<br />PDF DOWNLOAD
                        </h2>
                        <p>The process could take 1-2 mins.</p>
                        <img src={animation} />
                    </div>
                    <div className="inner alert">
                        <p>
                            You can find all your downloads in<br />
                            <strong>bulk downloads&nbsp; &gt; download history</strong> from the account dropdown menu
                        </p>
                    </div>
                </div>
                <div className={"box complete " + (!downloadLink ? "hidden" : "")}>
                    <div className="inner">
                        <h2>Your download<br />is ready</h2>
                        <p>Press the button below to download
                        </p>
                        <p><br /><a className="button no3d" onClick={completeDownloadCollection} href="#">Download</a></p>
                    </div>
                    <div className="inner alert">
                        <p>
                            You can find all your downloads in<br />
                            <strong>bulk downloads&nbsp; &gt; download history</strong> from the account dropdown menu
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}	