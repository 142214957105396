
import { Link, Outlet } from 'react-router-dom'
import '../assets/css/reset.css'
import '../assets/css/layout.css'
import { useState } from 'react';
import { LoadingProvider } from "../modules/core/LoadingProvider";
import { Urls, UserRoles } from '../Constants';
import { Role, useAuthContext } from '@pull/pull-groupm-csp-api';
import { UserDropDown } from '../modules/user/UserDropDown';
import { UserProvider } from '../modules/user/UserContext';
import $ from 'jquery'
import { GlobalProvider, useGlobalContext } from '../modules/core/GlobalContext';

const MasterAuthenticatedLayout = () => {

  const { currentSession, logout } = useAuthContext()
  const { supportPages } = useGlobalContext()

  const onClick = (event: any) => {

    if (!event.target.closest('.dropdown') || (event.target.closest('a') && event.target.closest('a').getAttribute('href') != "#")) 
      $('.dropdown.open').removeClass('open')

    if (!event.target.closest(".addfield") && !event.target.closest(".addTag"))
      $('.addfield.active').removeClass("active")

    if (!event.target.closest("#mobile-toggle-button")) {
      $('.mobile-toggle').addClass('hidden')
      $('#mobile-toggle-button').removeClass('opened')
    }
  }

  const onMobileMenuClick = (event: any) => {
    $('.mobile-toggle').toggleClass('hidden')
    $('#mobile-toggle-button').toggleClass('opened')
  }

  return (
    <UserProvider>
      <div onClick={onClick}>
        <div className="container">
          <header className="clearfix">
            <Link className='logo' to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="227.315" height="27.773" viewBox="0 0 227.315 27.773"><g fill="#9a3eff"><path d="M203.854 27.187c0-4.241-2.236-9.3-6.143-10.827a7.247 7.247 0 005.828-7.434c0-4.908-3.387-8.37-8.6-8.37h-12.1v26.632h5.326v-9.854a9.879 9.879 0 0110.319 9.854zm-9.293-14.953h-6.393v-6.58h6.393c2.242 0 3.652 1.179 3.652 3.271s-1.411 3.309-3.652 3.309zM180.254 27.187L169.411.555h-6.315l-10.765 26.632h5.832c2.015-4.516 7.665-6.9 13.272-7.406l2.972 7.406zm-18.2-9.755l4.219-10.522 3.182 7.935a22.39 22.39 0 00-7.4 2.588zM64.793.555v15.608c-3.253-5.641-9.58-12.667-12.2-15.608h-6.33v26.632h5.323V7.229c3.967 4.207 10.64 14.395 12.954 19.958h5.576V.555zM15.121 15.926c-5.779 0-9.8-15.371-9.8-15.371H0v26.632h5.326V13.222c1.709 4.11 5.57 8.011 9.8 8.011s8.127-3.9 9.833-8.055v14.008h5.321V.555h-5.323s-4.06 15.371-9.836 15.371zM35.647.555h5.326v26.632h-5.326zM75.48.555h10.272c7.569 0 12.745 5.9 12.745 13.353S93.32 27.186 85.752 27.186H75.48zm10.194 21.268c4.263 0 7.307-3.312 7.307-7.915s-3.044-7.99-7.307-7.99h-4.868v15.9zM208.258.555h19.06v5.1h-13.724v5.364h12.162v5.1h-12.162v5.972h13.724v5.1h-19.06zM112.574 10.522c-3.652-.38-4.678-1.291-4.678-2.891 0-1.484 1.749-2.495 4.338-2.495a5.085 5.085 0 014.715 3.468l4.99-2.2c-1.416-3.892-5.064-6.405-9.717-6.405-5.591 0-9.667 3.081-9.667 7.534 0 4.946 3.2 7.684 9.4 8.292 4.144.418 5.782 1.559 5.782 3.234 0 2.167-1.871 3.614-5.174 3.614-3.087 0-5.3-1.787-6.277-5.111l-4.942 1.908c1.369 5.142 5.432 8.3 11.227 8.3 6.39 0 10.5-3.614 10.5-8.866.002-5.161-3.727-7.696-10.497-8.382zM149.781.555h-5.337v14.033c-2.37-2.392-6.069-3.608-12.134-3.608V.555h-5.316v26.632h5.326V16.154c6.065 0 12.218 1.622 12.218 11.033h5.242z"></path></g></svg>
            </Link>
            <nav className="clearfix relative">
              <div className="mobile-toggle hidden">
                <ul>
                  <li className="">
                    <Link to="/search">Search</Link>
                  </li>
                  <li className="">
                    <Link to="/announcements">News</Link>
                  </li>
                  {(currentSession?.role == Role.Judge || currentSession?.role == Role.Administrator) &&
                    <li className="">
                      <Link to="/judging">Judging</Link>
                    </li>
                  }
                  <li className="">
                    <Link to="/cotm">COTM</Link>
                  </li>
                  <li className="">
                    <Link to="/pca">PCA</Link>
                  </li>
                  {supportPages && supportPages.length > 0 &&
                    <li>
                      <span tabIndex={0}>Support</span>
                      <ul>
                        {supportPages.map(page => {
                          return (<li key={page.id}><Link to={"/pages/" + page.slug ?? ""}>{page.title}</Link></li>)
                        })}
                      </ul>
                    </li>
                  }
                </ul>
                <Link to={Urls.CaseStudiesCreate} className="button">Create case study</Link>
              </div>
              <a href="#" onClick={onMobileMenuClick} id="mobile-toggle-button" className="absolute" title="navigation">
                <span></span><span></span><span></span>
              </a>
              <UserDropDown></UserDropDown>
            </nav>
          </header>
        </div>
        <Outlet />
        <footer className="colour-black">
          <div className="container clearfix">
            <div className="left">
              © {new Date().getFullYear()} Mindshare
              <br />
              v{window._env_.VERSION ?? "4.0.0"}
            </div>
            <div className="right">
              <a href="#">Return to top</a>
              <br />
              Powered by <a href="http://www.thepullagency.com">The Pull Agency</a>
            </div>
          </div>
        </footer>
        <svg aria-hidden="true" style={{ position: "absolute", width: "0", height: "0", overflow: "hidden" }} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <symbol id="icon-icon-5-mail-envelope-open2" viewBox="0 0 32 32">
              <title>icon-5-mail-envelope-open2</title>
              <path d="M16.818 21.991l-1.697 0.009h3.378l0.020-0.018-0.099 0.001 6.579-5.883v-8.091c0-0.557-0.455-1.009-1-1.009h-15c-0.552 0-1 0.444-1 1.002v8.198l6.551 5.78 2.267 0.011zM13.091 6l3.409-3 3.409 3h4.093c1.107 0 1.997 0.895 1.997 2v3.36l3 2.64v14.006c0 1.1-0.897 1.994-2.004 1.994h-20.993c-1.114 0-2.004-0.893-2.004-1.994v-14.006l3-2.64v-3.36c0-1.112 0.894-2 1.997-2h4.093zM18.428 6l-1.928-1.7-1.928 1.7h3.856zM26 12.677v2.656l1.5-1.333-1.5-1.323zM7 15.333v-2.656l-1.5 1.323 1.5 1.333zM13.5 23l-7 6h20l-7-6h-6zM27.685 28.725v0 0c0.194-0.183 0.315-0.442 0.315-0.725v-13l-8 7.017 7.685 6.708zM5.315 28.725l7.685-6.708-8-7.017v13c0 0.283 0.121 0.542 0.315 0.725v0 0z"></path>
            </symbol>
            <symbol id="icon-icon-57-document-download2" viewBox="0 0 32 32">
              <title>icon-57-document-download2</title>
              <path d="M16 25.050v-11.050h1v11.050l3.25-3.25 0.75 0.75-4.5 4.5-4.5-4.5 0.75-0.75 3.25 3.25zM19.5 3h0.5l6 7v18.009c0 1.093-0.894 1.991-1.997 1.991h-15.005c-1.107 0-1.997-0.899-1.997-2.007v-22.985c0-1.109 0.897-2.007 2.003-2.007h10.497zM19 4h-10.004c-0.55 0-0.996 0.455-0.996 0.995v23.009c0 0.55 0.455 0.995 1 0.995h15c0.552 0 1-0.445 1-0.993v-17.007h-4.002c-1.103 0-1.998-0.887-1.998-2.006v-4.994zM20 4.5v4.491c0 0.557 0.451 1.009 0.997 1.009h3.703l-4.7-5.5z"></path>
            </symbol>
            <symbol id="icon-icon-136-document-edit2" viewBox="0 0 32 32">
              <title>icon-136-document-edit2</title>
              <path d="M26.443 12.15l-11.286 11.3-2.606-2.606 11.292-11.294 2.6 2.6zM27.15 11.443l-2.599-2.599 1.727-1.728c0.391-0.391 1.024-0.388 1.417 0.003l1.18 1.177c0.392 0.391 0.395 1.025 0.005 1.416l-1.729 1.731zM11.904 21.611l2.495 2.495-3.135 0.617 0.64-3.113zM22 10v0l-6-7h-10.997c-1.106 0-2.003 0.898-2.003 2.007v22.985c0 1.109 0.891 2.007 1.997 2.007h15.005c1.103 0 1.997-0.898 1.997-1.991v-10.009l7.58-7.58c0.784-0.784 0.786-2.054 0.010-2.83l-1.18-1.179c-0.779-0.779-2.037-0.783-2.83 0.010l-3.58 3.58zM21 19v9.007c0 0.548-0.448 0.993-1 0.993h-15c-0.545 0-1-0.446-1-0.995v-23.009c0-0.54 0.446-0.995 0.996-0.995h10.004v4.994c0 1.119 0.895 2.006 1.998 2.006h4.002l-10 10-1 5 5-1 6-6zM16 4.5l4.7 5.5h-3.703c-0.546 0-0.997-0.452-0.997-1.009v-4.491z"></path>
            </symbol>
            <symbol id="icon-icon-26-trash-can" viewBox="0 0 32 32">
              <title>icon-26-trash-can</title>
              <path d="M21 6h4v1h-17v-1h4v-1c0-1.112 0.894-2 1.997-2h5.005c1.102 0 1.997 0.895 1.997 2v1zM8 8h17v18.999c0 1.658-1.343 3.001-3 3.001h-11c-1.666 0-3-1.344-3-3.001v-18.999zM9 9v18.009c0 1.099 0.893 1.991 1.992 1.991h11.016c1.1 0 1.992-0.898 1.992-1.991v-18.009h-15zM12 11v16h1v-16h-1zM16 11v16h1v-16h-1zM20 11v16h1v-16h-1zM14.003 4c-0.554 0-1.003 0.444-1.003 1v1h7v-1c0-0.552-0.438-1-1.003-1h-4.994z"></path>
            </symbol>
            <symbol id="icon-icon-92-inbox-download" viewBox="0 0 32 32">
              <title>icon-92-inbox-download</title>
              <path d="M16 16l-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zM18 11h5.4l4.375 7h-6.775v2.002c0 1.1-0.894 1.998-1.997 1.998h-5.005c-1.102 0-1.997-0.895-1.997-1.998v-2.002h-6.775l4.375-7h5.4v-1h-6l-5 8v9h25v-9l-5-8h-6v1zM22 19h6v7h-23v-7h6v1.5c0 1.381 1.115 2.5 2.496 2.5h6.008c1.379 0 2.496-1.11 2.496-2.5v-1.5z"></path>
            </symbol>
            <symbol id="icon-icon-22-eye" viewBox="0 0 32 32">
              <title>icon-22-eye</title>
              <path d="M16 9v0 0c9 0 13 7 13 7s-4 7-13 7c-9 0-13-7-13-7s4-7 13-7zM16 10c-8 0-11.8 6-11.8 6s3.8 6 11.8 6c8 0 11.8-6 11.8-6s-3.8-6-11.8-6v0 0zM16 20v0 0c-2.209 0-4-1.791-4-4s1.791-4 4-4c2.209 0 4 1.791 4 4s-1.791 4-4 4zM16 19c1.657 0 3-1.343 3-3s-1.343-3-3-3c-1.657 0-3 1.343-3 3s1.343 3 3 3v0 0zM16 17c0.552 0 1-0.448 1-1s-0.448-1-1-1c-0.552 0-1 0.448-1 1s0.448 1 1 1v0 0z"></path>
            </symbol>
            <symbol id="icon-icon-5-mail-envelope-open" viewBox="0 0 32 32">
              <title>icon-5-mail-envelope-open</title>
              <path d="M22.185 20.127l-2.185 1.873 5.923 5.077-0.808 0.808-5.996-5.139-5.223 0.023-0.021-0.018-5.99 5.135-0.808-0.808 5.923-5.077-2.185-1.873-3.815-3.227v-5.54l-3 2.64v14.006c0 1.101 0.89 1.994 2.004 1.994h20.993c1.107 0 2.004-0.895 2.004-1.994v-14.006l-3-2.64v5.54l-3.815 3.227zM13.091 6l3.409-3 3.409 3h-6.818zM9 7c-0.552 0-1 0.444-1 1.002v8.198l6.252 5.316h4.42l6.328-5.316v-8.198c0-0.553-0.455-1.002-1-1.002h-15z"></path>
            </symbol>
            <symbol id="icon-icon-57-document-download" viewBox="0 0 32 32">
              <title>icon-57-document-download</title>
              <path d="M16 25.050v-11.050h1v11.050l3.25-3.25 0.75 0.75-4.5 4.5-4.5-4.5 0.75-0.75 3.25 3.25zM19 3h-9.991c-1.109 0-2.009 0.898-2.009 2.007v22.985c0 1.109 0.891 2.007 1.997 2.007h15.005c1.103 0 1.997-0.891 1.997-1.997v-17.003h-4.994c-1.108 0-2.006-0.887-2.006-1.998v-6.002zM20 3v5.997c0 0.554 0.451 1.003 0.991 1.003h5.009l-6-7z"></path>
            </symbol>
            <symbol id="icon-icon-136-document-edit" viewBox="0 0 32 32">
              <title>icon-136-document-edit</title>
              <path d="M26.443 12.15l-2.6-2.6-11.292 11.294 2.606 2.606 11.286-11.3zM27.15 11.443l1.729-1.731c0.39-0.39 0.387-1.025-0.005-1.416l-1.18-1.177c-0.393-0.392-1.025-0.395-1.417-0.003l-1.727 1.728 2.599 2.599zM11.904 21.611l-0.64 3.113 3.135-0.617-2.495-2.495zM22 18v10.003c0 1.107-0.894 1.997-1.997 1.997h-15.005c-1.107 0-1.997-0.899-1.997-2.007v-22.985c0-1.109 0.899-2.007 2.009-2.007h9.991v6.002c0 1.111 0.898 1.998 2.006 1.998h3.994l4.58-4.58c0.793-0.793 2.051-0.788 2.83-0.010l1.18 1.179c0.776 0.776 0.775 2.046-0.010 2.83l-7.58 7.58zM16 3v5.997c0 0.554 0.451 1.003 0.991 1.003h5.009l-6-7z"></path>
            </symbol>
            <symbol id="icon-icon-26-trash-can2" viewBox="0 0 32 32">
              <title>icon-26-trash-can2</title>
              <path d="M21 6h4v1h-17v-1h4v-1c0-1.112 0.894-2 1.997-2h5.005c1.102 0 1.997 0.895 1.997 2v1zM8 8h17v18.999c0 1.658-1.343 3.001-3 3.001h-11c-1.666 0-3-1.344-3-3.001v-18.999zM12 11v16h1v-16h-1zM16 11v16h1v-16h-1zM20 11v16h1v-16h-1zM14.003 4c-0.554 0-1.003 0.444-1.003 1v1h7v-1c0-0.552-0.438-1-1.003-1h-4.994z"></path>
            </symbol>
            <symbol id="icon-icon-92-inbox-download2" viewBox="0 0 32 32">
              <title>icon-92-inbox-download2</title>
              <path d="M16 16l-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zM21 18h6.775l-4.375-7h-5.4v-1h6l5 8v9h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.105 0.896 2 1.997 2h5.005c1.103 0 1.997-0.888 1.997-2v-2z"></path>
            </symbol>
            <symbol id="icon-icon-22-eye2" viewBox="0 0 32 32">
              <title>icon-22-eye2</title>
              <path d="M17 9v0c9 0 13 7 13 7s-4 7-13 7c-9 0-13-7-13-7s4-7 13-7zM17 20c2.209 0 4-1.791 4-4s-1.791-4-4-4c-2.209 0-4 1.791-4 4s1.791 4 4 4v0zM17 19v0c-1.657 0-3-1.343-3-3s1.343-3 3-3c1.657 0 3 1.343 3 3s-1.343 3-3 3zM17 17c0.552 0 1-0.448 1-1s-0.448-1-1-1c-0.552 0-1 0.448-1 1s0.448 1 1 1v0z"></path>
            </symbol>
          </defs>
        </svg>
      </div>
    </UserProvider>
  )
}

export { MasterAuthenticatedLayout }
