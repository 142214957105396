import { Route, Routes, Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import lifestyle from '../assets/images/lifestyle.png';
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CaseStudiesApi, AwardsApi, AwardSubmissionInfo, SearchAwardSubmissionsColumns, useAuthContext, Configuration, CaseStudyOrder, ImageHelpers } from '@pull/pull-groupm-csp-api';
import Async from 'react-async';
import { useLoading } from '../modules/core/LoadingProvider';
import { LoadingComponent } from '../modules/core/LoadingComponent';
import { CaseStudyCard } from '../modules/case-studies/CaseStudyCard';
import { useQuery } from 'react-query';
import { CaseStudyHelpers } from '../helpers/caseStudyHelpers';
import { CaseStudySlider } from '../modules/case-studies/CaseStudySlider';
import { ErrorComponent } from '../modules/core/ErrorComponent';
import { useGlobalContext } from '../modules/core/GlobalContext';
import { Picture } from '@pull/pull-groupm-csp-api';
import { Helmet } from 'react-helmet';

const Default: FC = () => {

  const navigate = useNavigate();
  const [cotmEntries, setCotmEntries] = useState<AwardSubmissionInfo[]>()
  const [pcaEntries, setPcaEntries] = useState<AwardSubmissionInfo[]>()
  const [winners, setWinners] = useState<AwardSubmissionInfo[]>()
  const { getApiConfiguration } = useAuthContext()
  const caseStudiesApi = new CaseStudiesApi(getApiConfiguration())
  const awardsApi = new AwardsApi(getApiConfiguration())
  const { getSetting } = useGlobalContext()

  const getFeatured = async () => {
    var results = await caseStudiesApi.apiCaseStudiesSearchPost({
      featuredOnly: true,
      page: 1,
      pageSize: 1,
      order: CaseStudyOrder.Random,
      columns: {
          includeImages: true
      }
    });

    if (results.results && results.results.length == 1)
      return results.results[0]

    return null
  }

  const getCotmEntries = async () => {
    var results = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
      selectedAwardCategories: [5],
      recentSubmissionsOnly: true,
      page: 1,
      pageSize: 12,
      columns: {
        caseStudy: {
          includeImages: true
        }
      }
    });

    setCotmEntries(results.results)
  }

  const getPcaEntries = async () => {
    var results = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
      selectedAwardCategories: [54],
      recentSubmissionsOnly: true,
      page: 1,
      pageSize: 12,
      columns: {
        caseStudy: {
          includeImages: true
        }
      }
    });

    setPcaEntries(results.results)
  }

  const getWinners = async () => {

    var results = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
      winningSubmissionsOnly: true,
      includeExternalAwards: true,
      page: 1,
      pageSize: 6,
      columns: {
        caseStudy: {
          includeImages: true
        }
      }
    });

    setWinners(results.results)
  }

  const getEntries = async () => {
    await getCotmEntries()
    await getPcaEntries()
  }

  var { data: featured, status: featuredStatus } = useQuery("featured", getFeatured, { refetchOnWindowFocus: false, refetchOnMount: false })
  var { status: entriesStatus } = useQuery("en", getEntries, { refetchOnWindowFocus: false })
  var { status: winnersStatus } = useQuery("winners", getWinners, { refetchOnWindowFocus: false })

  return (
    <div className="App">
      <>
        <Helmet>
          <title>Home</title>
        </Helmet>
        {featuredStatus === "error" && <ErrorComponent></ErrorComponent>}
        {featuredStatus === "loading" && <LoadingComponent height='calc(90vh - 119px)'></LoadingComponent>}
        {featuredStatus === "success" && featured && (
          <>
            <section className="hero-featured">
              <picture>
                <Picture className='hero-img' url={CaseStudyHelpers.getPrimaryImageUrl(featured)} height={1200} lazy={true}></Picture>
              </picture>
              <div className="container clearfix">
                <span className="pill pill-secondary">
                  Featured
                </span>
                <Link to={`/casestudies/${featured.id}`}>
                  <>
                    <h1>
                      {featured.title}
                    </h1>
                  </>
                </Link>
              </div>
            </section>
          </>
        )}
        {entriesStatus === "error" && <ErrorComponent></ErrorComponent>}
        {entriesStatus === "loading" && <LoadingComponent height='1000px'></LoadingComponent>}
        {entriesStatus === "success" && featured && (
          <>
            <section className="section-padding">
              <div className="container">
                <h2 className="section-heading">Latest entries</h2>
                <CaseStudySlider title='Campaign of the Month' caseStudies={cotmEntries?.map(e => e.caseStudy) ?? []}></CaseStudySlider>
                <CaseStudySlider title='Performance Campaign Award' caseStudies={pcaEntries?.map(e => e.caseStudy) ?? []}></CaseStudySlider>
              </div>
            </section>
          </>
        )}
        {winnersStatus === "error" && <ErrorComponent></ErrorComponent>}
        {winnersStatus === "loading" && <LoadingComponent height='1000px'></LoadingComponent>}
        {winnersStatus === "success" && featured && (
          <>
            <section className="section-padding bg-primary">
              <div className="container">
                <h2 className="section-heading text-secondary">Previous Winners</h2>
                <ul className="previous-winners" role="list">
                  {winners?.map(entry => {
                    return (<li key={entry.id}>
                      <article>
                        <Link to={`/casestudies/` + entry.caseStudy?.id} className="campaign--img">
                          <div>
                            <figure>
                              <picture>
                                <Picture className='hero-img' url={CaseStudyHelpers.getPrimaryImageUrl(entry.caseStudy)} height={800} lazy={true}></Picture>
                              </picture>
                            </figure>
                            <div className="campaign--dates">
                              <span>{entry.isExternal ? "External award" : entry.awardCategoryShortName}</span>
                              <span>{entry.awardName}</span>
                            </div>
                          </div>
                          <h4 className="campaign--title">
                            {entry.caseStudy?.title}
                          </h4>
                        </Link>
                      </article>
                    </li>)
                  })}

                </ul>
                <div className="cta-buttons">
                  <Link to="/search" state={{ awardWinning: true }} className="button button-lg button-secondary">All Previous Winners</Link>
                </div>
              </div>
            </section>
          </>
        )}
        {featuredStatus === "success" && featured && (
          <>
            <section className="section-padding">
              <div className="container">
                <h2 className="section-heading">Explore more case studies</h2>
                <ul className="signposts" role="list">
                  <li>
                    <article>
                      <Link to="/search?order=viewed" className="signpost" data-variant="tertiary-bg">
                        <div className="signpost--heading">Most viewed Case Studies</div>
                      </Link>
                    </article>
                  </li>
                  <li>
                    <article>
                      <Link to="/search" className="signpost" data-variant="primary-bg">
                        <div className="signpost--heading">Search</div>
                      </Link>
                    </article>
                  </li>
                  {
                    (getSetting("HomepageTipsUrl")?.length > 0 || true) &&
                    <li>
                      <article>
                        <Link to={getSetting("HomepageTipsUrl")} className="signpost" data-variant="tertiary-bg">
                          <figure>
                            <picture>
                              <img className="hero-img" src={lifestyle} alt="" />
                            </picture>
                          </figure>
                          <div className="signpost--heading">{getSetting("HomepageTipsName")}</div>
                        </Link>
                      </article>
                    </li>
                  }
                </ul>
              </div>
            </section>
          </>)}
      </>
    </div>
  )
}

export { Default }
