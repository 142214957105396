import { AwardInfo, NewsInfo } from "@pull/pull-groupm-csp-api"
import moment from "moment"
import { useState } from "react"
import { Link } from "react-router-dom"

function NewsCard(props: NewsCardProps) {

    return (
        <div className="squareblock news">
            <Link to={"/announcements/" + props.news.id} className="ease ease-all">
                <div className="verticalcentre">
                    <h5>{props.news.title}</h5>
                    <span className="datetext">
                        <>{moment(props.news.metadata?.created).format("D MMMM YYYY")}</>
                    </span>
                </div>
            </Link>
        </div>
    )
}


interface NewsCardProps {
    news: NewsInfo
}


export { NewsCard }