import { useEffect, useState } from 'react'
import { Route, Routes, Outlet, Navigate, useNavigate } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import { FC } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { useAuthContext } from '@pull/pull-groupm-csp-api';


export function Logout() {
  const { logout } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    logout()

    setTimeout(() => {
      navigate("/")
    }, 250);
  });

  return (<></>)
}
