
import { useLoading } from './LoadingProvider';
import loadingAnimation from '../../assets/images/loading.svg'

const ErrorComponent = () => {

  return (
    <div className="section-padding">
      <div className="container">
        <div className="alert error">
          <p>An error has occurred retrieving data. Please try again later</p>
        </div>
      </div>
    </div>
)}

export { ErrorComponent }