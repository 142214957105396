import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider } from '@pull/pull-groupm-csp-api';

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
