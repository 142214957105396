import { AwardsApi, NewsApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { useQuery, useQueryClient } from "react-query"
import { Queries } from "../../Constants";
import { AwardJudgingPanel } from "../../modules/awards/AwardJudgingPanel";
import { AwardSubmissionCard } from "../../modules/awards/AwardSubmissionCard";
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard";
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { ErrorComponent } from "../../modules/core/ErrorComponent";
import { useState } from "react";
import moment from "moment";
import { NewsCard } from "../../modules/news/NewsCard";
import { Helmet } from "react-helmet";

function News() {

    const [page, setPage] = useState(1)
    const { getApiConfiguration } = useAuthContext()
    const newsApi = new NewsApi(getApiConfiguration())

    const getMaxPage = (pageSize: number, totalResults?: number) => {
        if (!totalResults || !pageSize)
            return 1;
        return Math.ceil(totalResults / pageSize)
    }

    const fetchNews = async (page: number) => {
        return await newsApi.apiNewsSearchPost({
            pageSize: 8,
            page: page ?? 1,
            onlyPublished: true,
            includeContent: false
        });
    }

    var { data: news, status: newsStatus } = useQuery({
        queryKey: ['news', page],
        queryFn: () => fetchNews(page),
        keepPreviousData: true
    })

    return <>
        <Helmet>
            <title>News</title>
        </Helmet>
        <section className="pageheading">
            <div className="colour-tertiary clearfix">
                <div className="container">
                    <h1 className="left">Latest News</h1>
                </div>
            </div>
        </section>
        <div className="container bodycopy">
            {newsStatus === "error" && <ErrorComponent></ErrorComponent>}
            {newsStatus === "loading" && <LoadingComponent height="600px"></LoadingComponent>}
            {newsStatus === "success" && (
                <>
                    <div className="clearfix gridcontainer">
                        {news && news?.results && news.results.length > 0 && news.results?.map((news, index) => {
                            return (
                                <NewsCard key={news.id} news={news}></NewsCard>
                            )
                        })}
                    </div>
                    {news && news.page && news.totalCount && news.totalCount > 0 &&
                        <div className="pagination">
                            {(news.page) > 1 ?
                                <button className="button" onClick={() => setPage((news?.page ?? 1) - 1)}>« Newer</button> : ""}
                            &nbsp;&nbsp;
                            {(news.page) < getMaxPage(news.page, news.totalCount) ?
                                <button className="button" onClick={() => setPage((news?.page ?? 1) + 1)}>Older »</button> : ""}
                        </div>
                    }
                </>
            )}
        </div>
    </>
}

export { News }