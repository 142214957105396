import { AwardInfo, AwardsApi, useAuthContext } from "@pull/pull-groupm-csp-api"
import { useState } from "react"
import { AwardSubmissionCard } from "./AwardSubmissionCard"
import { QueryClient, useQueryClient } from 'react-query'
import { Queries } from "../../Constants";
import spinner from '../../assets/images/tail-spin.svg'
import { useGlobalContext } from "../core/GlobalContext";

function AwardScorePanel(props: AwardScorePanelProps) {

    const queryClient = useQueryClient();
    const { getSetting } = useGlobalContext()
    const { getApiConfiguration } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration());
    const [score, setScore] = useState<number | undefined>(props.currentScore);
    const [comment, setComment] = useState(props.currentComment);
    const [submitting, setSubmitting] = useState(false);

    async function handleScoreChange(event: React.FormEvent<HTMLInputElement>) {
        setScore(parseInt(event.currentTarget.value))
    }

    async function saveScore(event: React.MouseEvent<HTMLAnchorElement>) {

        event.preventDefault();

        if (submitting)
            return false

        setSubmitting(true)

        try {
            await awardsApi.apiAwardsAwardSubmissionsScorePost({
                awardSubmissionID: props.awardSubmissionID,
                score,
                comment
            })

            if (props.scoreChanged)
                props.scoreChanged(score)

            queryClient.invalidateQueries(Queries.AwardJudging)
        }
        finally {
            setSubmitting(false)
        }
    }

    return (
        <div className="twocol-parent">
            <div>
                <strong>Your Score</strong> (with 10 being the highest)
                <div className="ratings">
                    <p>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(scoreNumber => {
                            return <>
                                {(!props.disabled || score == scoreNumber) &&
                                    <>
                                        <input type="radio"
                                            checked={scoreNumber == score}
                                            disabled={props.disabled}
                                            name={`scoring_${props.awardSubmissionID}`}
                                            value={scoreNumber} id={`scoring_${props.awardSubmissionID}_${scoreNumber}`}
                                            onChange={handleScoreChange} />
                                        <label htmlFor={`scoring_${props.awardSubmissionID}_${scoreNumber}`}>{scoreNumber}</label>
                                    </>
                                }
                            </>
                        })}
                    </p>
                </div>
            </div>
            <div>
                <strong>Your Comment</strong> (click to edit)
                <div className="comment">
                    <p>
                        <textarea placeholder={getSetting("JudgingCommentPlaceholder")} disabled={props.disabled} onChange={(e) => setComment(e.currentTarget.value)}>{props.currentComment}</textarea>
                    </p>
                </div>
                {!props.disabled &&
                    <a href="#" onClick={saveScore} className="button no3d right updatescore">
                        {!submitting && "Save Changes"}
                        {submitting && (
                            <img src={spinner} style={{ height: "25px" }} />
                        )}
                        </a>
                }
            </div>
        </div>
    )
}


interface AwardScorePanelProps {
    awardSubmissionID?: number,
    currentScore?: number,
    currentComment?: string,
    disabled?: boolean,
    scoreChanged?: (score?: number) => void;
}


export { AwardScorePanel }