import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AwardsApi, AwardSubmissionInfo, CaseStudyInfo, Picture, Role, useAuthContext } from "@pull/pull-groupm-csp-api"
import { UserRoles } from "../../Constants";
import moment from 'moment'
import { CaseStudyActionBar } from "../case-studies/CaseStudyActionBar";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { AwardScorePanel } from "./AwardScorePanel";

function AwardSubmissionCard(props: AwardSubmissionCardProps) {

    const { getApiConfiguration } = useAuthContext();
    const awardsApi = new AwardsApi(getApiConfiguration());
    const dropdown = useRef<HTMLDivElement>(null);
    const [activeScorePanel, setActiveScorePanel] = useState(false);
    const [score, setScore] = useState<number | undefined>(props.awardSubmission?.judgeScore?.score);

    async function handleScoreUpdated(score?: number) {
        setScore(score)
        setActiveScorePanel(false)
    }

    return (
        props.awardSubmission?.caseStudy ?
            <div className="resultblock clearfix" data-id="108842">
                <div className="left figure">
                    <Picture url={CaseStudyHelpers.getPrimaryImageUrl(props.awardSubmission.caseStudy)} height={300} className="absolute" alt={props.awardSubmission.caseStudy.title} />
                    <div className="views absolute colour-primary alpha">
                        <div>
                            <strong>{props.awardSubmission.caseStudy.viewCount}</strong>views
                        </div>
                    </div><Link className="absolute" to={`/casestudies/${props.awardSubmission.caseStudy.id}?submission=${props.awardSubmission.id}`}>{props.awardSubmission.caseStudy.title}</Link>
                </div>
                <div className="left">
                    <div className="details">
                        <h3>{props.awardSubmission.caseStudy.title}</h3>
                        <ul>
                            <li>
                                <strong>Brand: </strong>&nbsp;&nbsp;
                                {props.awardSubmission.caseStudy.tags?.brand?.value}
                            </li>
                            <li>
                                <strong>Implementation Date: </strong>&nbsp;&nbsp;
                                {props.awardSubmission.caseStudy.fields?.implementationDate ? moment(props.awardSubmission.caseStudy.fields?.implementationDate).format("D MMMM YYYY") : ""}
                            </li>
                            <li>
                                <strong>
                                    Country:
                                </strong>&nbsp;&nbsp;
                                {props.awardSubmission.caseStudy.countries?.map(c => c.name).join(", ")}
                            </li>
                            {CaseStudyHelpers.getAwards(props.awardSubmission.caseStudy) ?
                                <li>
                                    <strong>
                                        Awards:
                                    </strong>&nbsp;&nbsp;
                                    {CaseStudyHelpers.getAwards(props.awardSubmission.caseStudy)}
                                </li> : ""}
                        </ul>
                    </div>
                    <div className="container infopanel colour-lightgrey iconblock relative">
                        <CaseStudyActionBar caseStudy={props.awardSubmission.caseStudy} />
                        {props.awardSubmission.judgingOpen && (
                            <div>
                                <div className="score relative">
                                    <a className={"enabled " + (activeScorePanel ? "opened" : "")} href="#" onClick={(e) => {e.preventDefault(); setActiveScorePanel(!activeScorePanel)}}>
                                        <strong><span className="averagescore">{score ?? "-"}</span>Your Score</strong>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={"score-toggle-box score-panel clearfix colour-primary " + (activeScorePanel ? "opened" : "")}>
                    <div className="container relative">
                        <div className="inner white-text">
                            <div>
                                <AwardScorePanel disabled={!props.awardSubmission.judgingOpen} 
                                                 awardSubmissionID={props.awardSubmission.id} 
                                                 currentScore={score} 
                                                 currentComment={props.awardSubmission.judgeScore?.comment} 
                                                 scoreChanged={handleScoreUpdated}></AwardScorePanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <></>
    )
}

interface AwardSubmissionCardProps {
    awardSubmission?: AwardSubmissionInfo
}


export { AwardSubmissionCard }