import { useRef } from "react";
import { Link } from "react-router-dom";
import { CaseStudyInfo, Picture, Role, useAuthContext } from "@pull/pull-groupm-csp-api"
import { UserRoles } from "../../Constants";
import moment from 'moment'
import { CaseStudyActionBar } from "./CaseStudyActionBar";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";

function CaseStudyCard(props: CaseStudyCardProps) {

    const dropdown = useRef<HTMLDivElement>(null);

    function toggleDropdown(event: React.MouseEvent<HTMLAnchorElement>) {

        event.preventDefault()
        dropdown.current?.classList.toggle("open")
    }

    return (
        props.caseStudy ?
            <div className="resultblock clearfix">
                <div className="left figure">
                    {!props.caseStudy.fields?.isInternal ? 
                    <div className="pill-container" data-position="floating">
                        <span className="pill pill-dark">Non-Mindshare case study</span>
                    </div> : "" }
                    <Picture url={CaseStudyHelpers.getPrimaryImageUrl(props.caseStudy)} height={300} className="absolute" alt={props.caseStudy.title} lazy={true} />
                    <div className="views absolute colour-primary alpha">
                        <div>
                            <strong>{props.caseStudy.viewCount}</strong>views
                        </div>
                    </div><Link className="absolute" to={`/casestudies/${props.caseStudy.published ? props.caseStudy.id : props.caseStudy.hash}`}>{props.caseStudy.title}</Link>
                </div>
                <div className="left">
                    <div className="details">
                        <h3>{props.caseStudy.title}</h3>
                        <ul>
                            <li>
                                <strong>Brand: </strong>&nbsp;&nbsp;
                                {props.caseStudy.tags?.brand?.value}
                            </li>
                            <li>
                                <strong>Implementation Date: </strong>&nbsp;&nbsp;
                                {props.caseStudy.fields?.implementationDate ? moment(props.caseStudy.fields?.implementationDate).format("D MMMM YYYY") : ""}
                            </li>
                            <li>
                                <strong>
                                    Country:
                                </strong>&nbsp;&nbsp;
                                {props.caseStudy.countries?.map(c => c.name).join(", ")}
                            </li>
                            {CaseStudyHelpers.getAwards(props.caseStudy) ?
                                <li>
                                    <strong>
                                        Awards:
                                    </strong>&nbsp;&nbsp;
                                    {CaseStudyHelpers.getAwards(props.caseStudy)}
                                </li> : ""}
                        </ul>
                    </div>
                    <div className="container infopanel colour-lightgrey iconblock relative">
                        <CaseStudyActionBar caseStudy={props.caseStudy}></CaseStudyActionBar>
                    </div>
                </div>
            </div> : <></>
    )
}

interface CaseStudyCardProps {
    caseStudy?: CaseStudyInfo
}


export { CaseStudyCard }