import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CaseStudiesApi, Role, useAuthContext } from "@pull/pull-groupm-csp-api";
import { useQuery } from "react-query";
import { Queries, Urls } from "../../Constants";
import { useUserContext } from "./UserContext";

export const UserDropDown = () => {
    const REFRESH_TIME = 900000;

    const { getApiConfiguration } = useAuthContext()
    const { userLastSavedSearchRun } = useUserContext()
    const userAccount = useRef<HTMLDivElement>(null)
    const { currentSession } = useAuthContext()

    const toggleDropdown = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        userAccount.current?.classList.toggle("open")
    }

    const hasUserWatchlistActivity = async () => {
        var caseStudiesApi = new CaseStudiesApi(getApiConfiguration());
        return await caseStudiesApi.apiCaseStudiesSavedSearchNewActivityGet();
    }

    const { data: hasActivity, refetch } = useQuery(Queries.UserWatchlistActivity, hasUserWatchlistActivity, {refetchOnWindowFocus: true, refetchInterval: REFRESH_TIME});

    useEffect(() => {
        refetch()
    }, [userLastSavedSearchRun])

    return (<div ref={userAccount} id="useraccount" className={'dropdown absolute'} >
        <a href="#" className={"account " + (hasActivity ? "new-activity" : "")} onClick={toggleDropdown}>
            <svg x="0px" y="0px" viewBox="0 0 22 22">
                <g>
                    <g>
                        <path d="M21.6,20.2c-0.7-2.1-1.2-3.2-2.5-5.4c-0.6-1-2-1.8-3.1-1.8c-0.2,0-0.3,0-0.5,0c-0.9,1.2-2.3,2-4,2s-3.1-0.8-4-2
                                  c-0.2,0-0.3,0-0.5,0c-1.1,0-2.5,0.8-3.1,1.8c-1.2,2.1-1.8,3.2-2.5,5.4C1.1,21.2,1.9,22,3,22c6.8,0,10.2,0,17,0
                                  C21.1,22,21.9,21.2,21.6,20.2z"></path>
                    </g>
                    <ellipse cx="11.5" cy="6.5" rx="5.5" ry="6.5"></ellipse>
                </g>
            </svg>
            My account</a>
        <div className="dropme absolute dropdown-list">
            <ul>
                <li>
                    <Link to={Urls.UserBulkDownloads}>Bulk Downloads</Link>
                </li>
                <li>
                    <Link to={Urls.UserWatchlist} className={hasActivity ? "new-activity" : ""}>
                        Search Watchlist
                        {hasActivity ?
                            <em className="pill">New Activity</em> : ""}
                    </Link>
                </li>
                <li>
                    <Link to={Urls.UserCaseStudies}>My Case Studies</Link>
                </li>
                <li>
                    <Link to={Urls.UserCaseStudiesDraft}>My Draft Case Studies</Link>
                </li>
                {currentSession?.role == Role.Administrator && window._env_.ADMIN_URL ?
                    <li>
                        <a target={"_blank"} href={window._env_.ADMIN_URL}>Admin</a>
                    </li> : ""
                }
                <li>
                    <Link to={Urls.UserLogout} rel='nofollow'>Log out <em>({currentSession?.email})</em></Link>
                </li>
            </ul>
        </div>
    </div>)
}