import { Route, Routes, Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/images/logo.svg';
import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '@pull/pull-groupm-csp-api';
import { Helmet } from 'react-helmet';
import { Urls } from '../Constants';

const Landing: FC = () => {

   const { getApiConfiguration, currentSession } = useAuthContext();
   const navigate = useNavigate();

   useEffect(() => {
      if (currentSession) {
         navigate("/home")
      }
   }, []);

   return (
      <section className="home hero slideshow" style={{ height: "700px", background: "#000" }}>
         <div className="featuredimage absolute active login">
            &nbsp;
         </div>
         <div className="loginbox absolute">
            <h1>Welcome to the home of Good Growth.</h1>
            <p>
               Get started now
            </p>
            <br />
            <Link className='button' to={Urls.UserLogin}>Sign in</Link>
            <br /><br />
            <p></p>
         </div>
      </section>
   )
}

export { Landing }
