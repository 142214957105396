import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CaseStudiesApi, CaseStudyInfo, MediaApi, useAuthContext } from "@pull/pull-groupm-csp-api";
import { CaseStudyProgress, CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import { CaseStudyEditContainer, IEditCaseStudyStepProps, useCaseStudyEditContext } from "./_CaseStudyEditContainer";
import { CaseStudyWorkflowManager } from "../../../managers/CaseStudyWorkflowManager";
import { CaseStudyHelpers } from "../../../helpers/caseStudyHelpers";


export function CaseStudyEditPublish(props: IEditCaseStudyStepProps) {

    const navigate = useNavigate();
    const { caseStudy, fetching } = useCaseStudyEditContext()
    const { getApiConfiguration } = useAuthContext();
    const [loading, setLoading] = useState(false)



    async function publishCaseStudy() {
        var apiConfig = await getApiConfiguration();
        var caseStudiesApi = new CaseStudiesApi(apiConfig)
        var workflowManager = new CaseStudyWorkflowManager(caseStudiesApi)
        try {
            await workflowManager.publishCaseStudy(caseStudy?.id ?? 0)
        }
        catch (ex) {
        }

        props.onComplete(CaseStudyStep.Publish, false)
    }

    return (
        !fetching && !loading ?
            <div>
                <div className="container bodycopy material">

                    <div className="alert normal">
                        {!caseStudy?.published ?
                            <p> Your Case Study is currently unpublished. You can publish it now by pressing the button below.</p> :
                            <p> Your Case Study is published.</p>
                        }
                    </div>

                    <div className="clearfix button-block">
                        {!caseStudy?.published ?
                            <input type="submit" id="proceed" className="right no3d" onClick={publishCaseStudy} value="Publish Case Study &nbsp; >" /> :
                            <Link className="button right no3d" to={`/casestudies/${caseStudy?.id ?? 0}`} style={{ marginBottom: "0px" }}>Go to Case Study  &gt;</Link>
                        }
                    </div>
                </div>
            </div>
            : <LoadingComponent loadingAnimationDelay={0}></LoadingComponent>
    )
}
