import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { AwardsApi, AwardSubmissionInfo, CaseStudiesApi, CaseStudiesApiFactory, CaseStudyInfo, ImageHelpers, Picture, Role, useAuthContext } from "@pull/pull-groupm-csp-api";
import { CaseStudyActionBar } from "../../modules/case-studies/CaseStudyActionBar";
import Slider from "react-slick";
import '../../assets/css/slick.css'
import '../../assets/css/slick-theme.css'
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { Queries, Urls } from "../../Constants";
import { useQuery } from "react-query";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { AwardScorePanel } from "../../modules/awards/AwardScorePanel";
import { ErrorComponent } from "../../modules/core/ErrorComponent";
import { Helmet } from "react-helmet";
import loadingAnimation from '../../assets/images/oval-purple.svg'
import { useGlobalContext } from "../../modules/core/GlobalContext";

export function CaseStudyDetail() {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'linear'
    };
    
    const { getSetting } = useGlobalContext()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = useParams();
    const submissionID = parseInt(searchParams.get('submission') ?? "");
    const { getApiConfiguration, currentSession } = useAuthContext();
    const video = useRef<HTMLVideoElement>(null);
    const videoOverlay = useRef<HTMLDivElement>(null);
    const [showAwardPanel, setShowAwardPanel] = useState(false)
    const [activeAward, setActiveAward] = useState<AwardSubmissionInfo | undefined>()
    const [caseStudySubmissions, setCaseStudySubmissions] = useState<Array<AwardSubmissionInfo>>([]);
    const caseStudyApi = new CaseStudiesApi(getApiConfiguration());
    const awardsApi = new AwardsApi(getApiConfiguration());

    async function getCaseStudy(): Promise<CaseStudyInfo> {

        try {

            var intId = parseInt(params.id ?? "")
            var caseStudy = intId > 0 && !isNaN(params.id as any) ?
                await caseStudyApi.apiCaseStudiesIdIdGet(intId) :
                await caseStudyApi.apiCaseStudiesHashHashGet(params.id ?? "")

            if (!caseStudy) {
                navigate(Urls.NotFound)
            }
            else {
                await caseStudyApi.apiCaseStudiesIdViewPost(caseStudy?.id ?? 0)
            }

            var submissions = await awardsApi.apiAwardsAwardSubmissionsSearchPost({
                caseStudyID: caseStudy.id
            })

            var hasOpenSubmissions = (submissions?.results?.filter(r => r.judgingOpen) ?? []).length > 0;
            //Only shown the open submissions, otherwise show submissions where the judge has left a score
            var submissionsToShow = submissions?.results?.filter(r => r.judgingOpen || (!hasOpenSubmissions && r.judgeScore)) ?? [];
            setCaseStudySubmissions(submissionsToShow)

            if (submissionID) {
                var selectedSubmission = submissionsToShow.find(s => s.id == submissionID)
                if (selectedSubmission) {
                    setActiveAward(selectedSubmission)
                    setShowAwardPanel(true)
                }

            }

            return caseStudy
        }
        catch (error) {
            console.log(error);
            throw new Error("Case Study could not be found")
        }
    }

    const { data: caseStudy, status } = useQuery(Queries.CaseStudy + params.id, getCaseStudy, { refetchOnWindowFocus: false });

    function playVideo() {
        videoOverlay.current?.classList.add('open')
        setTimeout(() =>
            video.current?.play()
            , 250);
    }

    function closeVideoModal(event: React.MouseEvent<HTMLDivElement>) {
        if (event.target == videoOverlay.current) {
            videoOverlay.current?.classList.remove('open')
            video.current?.pause();
        }
    }


    function getPrimaryImageUrl(caseStudy: CaseStudyInfo) {
        if (caseStudy.images && caseStudy.images?.length > 0)
            return caseStudy.images[0].url
        else
            return ""
    }

    function toggleAwardPanel(submission?: AwardSubmissionInfo) {

        if (!submission) {
            setActiveAward(undefined)
            setShowAwardPanel(false)
            return
        }

        var changedAward = submission?.id != activeAward?.id

        if (changedAward) {
            setActiveAward(submission)
            setShowAwardPanel(true)
        }
        else {
            setShowAwardPanel(!showAwardPanel)

            if (showAwardPanel)
                setTimeout(() => { setActiveAward(undefined) }, 250);
        }
    }

    async function updateScore(submission: AwardSubmissionInfo, score?: number) {
        toggleAwardPanel(submission)
        var award = caseStudySubmissions.find(cs => cs.id == submission.id)
        if (award) {
            award.judgeScore = {
                score
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{caseStudy?.title}</title>
            </Helmet>
            {status === "error" && <ErrorComponent></ErrorComponent>}
            {status === "loading" && <LoadingComponent height="90vh"></LoadingComponent>}
            {status === "success" && (
                <div>
                    {!caseStudy.published &&
                        <div className="alert normal mb-0">
                            <div className="container">
                                <p>This is a preview of how the case study will appear for other users. It has not yet been published.</p>
                            </div>
                        </div>}
                    {caseStudy.published && !caseStudy.fields?.isInternal &&
                        <div className="alert normal mb-0">
                            <div className="container">
                                <p> {getSetting("CaseStudyExternalBanner") ?? "This is a non-Mindshare case study." }</p>
                            </div>
                        </div>}
                    <section className="hero" style={{ backgroundImage: (caseStudy.images && caseStudy.images.length > 0) ? `url('${ImageHelpers.getImageUrl(getPrimaryImageUrl(caseStudy), undefined, 1200)}'), url('${loadingAnimation}')` : "" }}>
                        <div className="container">
                            <div className="author colour-primary alpha absolute">
                                <div className="clearfix">
                                    <div className="left">
                                        <div className="imageblock">
                                            <div className="views relative">
                                                <div>
                                                    <strong>{caseStudy.viewCount}</strong>views
                                                </div>
                                            </div>
                                        </div>
                                        <div className="headings left">
                                            <h1>{caseStudy.title}</h1>
                                        </div>
                                    </div>
                                    <div className="left authorinfo">
                                        <ul>
                                            {caseStudy.tags?.advertiser ?
                                                <li><strong>Advertiser: </strong>{caseStudy.tags?.advertiser?.value}</li> :
                                                ""
                                            }
                                            <li><strong>Brand: </strong>{caseStudy.tags?.brand?.value}</li>
                                            <li><strong>Created by: </strong>{caseStudy?.metadata?.createdBy ?? "N/A"}</li>
                                            {caseStudy.fields?.implementationDate ?
                                                <li>
                                                    <strong>Implementation Date: </strong>
                                                    {moment(caseStudy.fields?.implementationDate).format("D MMM YYYY")}</li> :
                                                ""
                                            }
                                            {caseStudy.countries ?
                                                <li>
                                                    <strong>Country: </strong>
                                                    <span>{caseStudy.countries.map(c => c.name).join(", ")}</span>
                                                </li> : ""}
                                            {CaseStudyHelpers.getAwards(caseStudy) ?
                                                <li>
                                                    <strong>
                                                        Awards:
                                                    </strong>&nbsp;&nbsp;
                                                    {CaseStudyHelpers.getAwards(caseStudy)}
                                                </li> : ""}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {caseStudy.video &&
                            <a className="playbutton absolute" onClick={playVideo}>Play video</a>
                        }
                    </section>
                    <div className="colour-lightgrey">
                        <div className="container infopanel colour-lightgrey iconblock relative">
                            <CaseStudyActionBar caseStudy={caseStudy}></CaseStudyActionBar>
                            {caseStudySubmissions.length > 0 &&
                                <div>
                                    <p className="score-type-summary">Your Scores</p>
                                    <div className="score relative" data-variant="multiple-awards">
                                        {caseStudySubmissions.map(submission => {
                                            return (
                                                <a id="cotm-btn" className={"average-score button " + (activeAward?.id == submission.id ? "opened" : "")}
                                                    role="button" href="#" onClick={(e) => { e.preventDefault(); toggleAwardPanel(submission) }}>
                                                    <p className="awardname">
                                                        <strong>{submission.awardCategoryShortName}</strong></p>
                                                    <p>
                                                        <strong>{submission.judgeScore?.score ?? "-"}</strong>
                                                    </p>
                                                </a>)
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                        {caseStudySubmissions.map(submission => {
                            return (
                                (<div id="cotm-scores" className={"score-toggle-box bg-grey-lighter " + (showAwardPanel && submission.id == activeAward?.id ? "opened" : "")} data-variant="grey">
                                    <div className="container relative">
                                        <div className="inner">
                                            <div>
                                                {activeAward?.judgingOpen ?
                                                    <span className="pill pill-success">Judging open</span> :
                                                    <span className="pill pill-error">Judging closed</span>}
                                                <h2 className="heading">{submission?.awardCategoryName} - {submission?.awardName}</h2>
                                                {currentSession?.role == Role.Administrator && submission?.averageScore &&
                                                    (<div className="current-average">
                                                        <strong>Average Score</strong>
                                                        <strong>
                                                            <p>{submission.averageScore}</p>
                                                        </strong>
                                                    </div>)
                                                }
                                                <AwardScorePanel awardSubmissionID={submission?.id}
                                                    currentScore={submission?.judgeScore?.score}
                                                    currentComment={submission?.judgeScore?.comment}
                                                    disabled={!submission?.judgingOpen}
                                                    scoreChanged={(score) => updateScore(submission, score)}
                                                ></AwardScorePanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            )
                        })}
                    </div>


                    <div className="container bodycopy">

                        {caseStudy.fields?.summary ?
                            <div className="clearfix">
                                <div className="justified">
                                    <h2>Summary</h2>
                                    <p>
                                        <p>{caseStudy.fields?.summary}</p>
                                    </p>
                                </div>
                            </div> : ""}

                        <div className="clearfix">
                            <div className="justified">
                                <h2>Challenge</h2>
                                <p>
                                    <p>{caseStudy.fields?.challenge}</p>
                                </p>
                            </div>
                        </div>

                        {caseStudy.fields?.insight ?
                        <div className="clearfix">
                            <div className="justified">
                                <h2>Insight</h2>
                                <p>
                                    <p>{caseStudy.fields?.insight}</p>
                                </p>
                            </div>
                        </div> : ""}

                        <div className="slideshow relative">
                            <div className="slider">
                                <Slider {...settings}>
                                    {caseStudy.images && caseStudy.images.map(image => {
                                        return (
                                            <div>
                                                <a href={image.url ?? ""} target="_blank">
                                                    <Picture url={image.url} height={600} lazy={true} className={""}></Picture>
                                                </a>
                                            </div>
                                        )
                                    })}
                                </Slider>


                            </div>
                        </div>

                        <div className="justified">
                            <h2>
                                Idea
                            </h2>
                            <p>
                                <p>{caseStudy.fields?.idea}</p>
                            </p>
                        </div>

                        <div className="justified">
                            <h2>
                                Delivery
                            </h2>
                            <p>
                                <p>{caseStudy.fields?.delivery}</p>
                            </p>
                        </div>

                        <div className="justified">
                            <h2>Results</h2>
                            <p>
                                <p>{caseStudy.fields?.results}</p>
                            </p>
                        </div>

                    </div>
                    <div ref={videoOverlay} className="video-overlay" onClick={closeVideoModal}>
                        <div className="inner-video-overlay">
                            <video ref={video} controls><source src={caseStudy.video?.url}></source></video>
                            {currentSession?.role == Role.Administrator ?
                                <a href={caseStudy.video?.url} className="button" download="">Download Video</a> : ""}
                        </div>
                    </div>
                </div>
            )}
        </>


    )
}