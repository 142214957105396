import { CaseStudiesApi, CaseStudiesApiFactory, CaseStudyImageInfo, CaseStudyInfo, CaseStudyVideoInfo } from '@pull/pull-groupm-csp-api'

export class CaseStudyWorkflowManager {

    readonly caseStudiesApi: CaseStudiesApi

    constructor(caseStudiesApi: CaseStudiesApi) {
        this.caseStudiesApi = caseStudiesApi;
    }

    async saveCaseStudyText(fields: any): Promise<CaseStudyInfo> {

        var request = {
            id: fields.id,
            title: fields.title,
            challenge: fields.challenge,
            delivery: fields.delivery,
            idea: fields.idea,
            insight: fields.insight,
            results: fields.results,
            summary: fields.summary,
        };
        
        var response = await this.caseStudiesApi.apiCaseStudiesPost(request)
        return response
    }

    async saveCaseStudyTags(fields: any): Promise<CaseStudyInfo> {

        var request = {
            id: fields.id,
            regions: fields.regions,
            countries: fields.countries,
            implementationDate: fields.implementationDate,
            advertiser: fields.advertiser,
            brand: fields.brand,
            implementationDateSpecified: true,
            tags: fields.sectors.concat(fields.channels).concat(fields.objectives).concat(fields.audiences),
            team: fields.team,
            goodGrowth: fields.goodGrowth
        };

        var response = await this.caseStudiesApi.apiCaseStudiesPost(request)
        return response
    }

    async saveCaseStudyImages(id:number, images: Array<CaseStudyImageInfo>): Promise<CaseStudyInfo> {

        var request = {
            id: id,
            images: images
        };

        var response = await this.caseStudiesApi.apiCaseStudiesPost(request)

        return response
    }

    async saveCaseStudyVideo(id:number, videoID: number): Promise<CaseStudyInfo> {

        var request = {
            id: id,
            video: videoID
        };

        var response = await this.caseStudiesApi.apiCaseStudiesPost(request)

        return response
    }

    async publishCaseStudy(id:number): Promise<boolean> {
        return await this.caseStudiesApi.apiCaseStudiesIdPublishPost(id)
    }
}